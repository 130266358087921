import { Option } from '@nickel/core-ui/components/SearchInput/types'

import { AddressPredictionListItemView } from 'src/services/location-api'

export const formatPredictionToOption = (predictions: AddressPredictionListItemView[]) => {
    const filteredEmptyPredictions = predictions.filter(({ id, label }) => !!id && !!label)

    return filteredEmptyPredictions.map(({ id, label }) => ({
        label,
        value: id
    })) as Option[]
}
