import React, { MouseEvent } from 'react'

import { ChevronRight } from '@nickel/core-ui/components/Icons'
import { Option } from '@nickel/core-ui/components/SearchInput/types'
import Text from '@nickel/core-ui/components/Text'
import { References } from '@nickel/foundations/tokens/color'

import { ButtonPrediction, ListPrediction } from './styles'

export const ResultPrediction = ({
    onSelect,
    predictions
}: {
    onSelect: (id: string) => (e: MouseEvent) => void
    predictions: Option[]
}) => (
    <ListPrediction data-testid="mobileSuggestion#list">
        {predictions.map(({ label, value }) => (
            <ButtonPrediction key={label} onClick={(e: MouseEvent) => onSelect(value)(e)} type="button">
                <Text>{label}</Text>
                <ChevronRight color={References.REF_NEUTRAL_N900} />
            </ButtonPrediction>
        ))}
    </ListPrediction>
)
