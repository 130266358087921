import React, { useContext } from 'react'

import { useTranslation } from 'react-i18next'

import ToggleButton from '@nickel/core-ui/components/ToggleButton'

import { ToggleWrapper } from '../styles'

import { AppContext } from 'src/context'
import { AppActionsType } from 'src/context/types'
import { SnowplowAction, trackClickEvent } from 'src/utils/Tracking/utils'

export const OnlyOpened = () => {
    const { t } = useTranslation('home')
    const { state, dispatch } = useContext(AppContext)

    const _updateOnlyOpened = (checked: boolean) => {
        trackClickEvent(SnowplowAction.STATUS, checked ? 'activated' : 'deactivated')
        dispatch({
            type: AppActionsType.UPDATE_FILTERS,
            payload: {
                onlyOpened: checked
            }
        })
    }

    return (
        <ToggleWrapper onClick={(e) => e.stopPropagation()}>
            <ToggleButton
                checked={state?.filters?.onlyOpened}
                id="toggle"
                label=""
                onClick={_updateOnlyOpened}
                testId="onlyOpenedToggle"
            />
            <span>{t('isOpenedNow')}</span>
        </ToggleWrapper>
    )
}
