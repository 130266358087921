import React from 'react'

import { useTranslation } from 'react-i18next'

import Tag from '@nickel/core-ui/components/Tag'
import { TagVariant } from '@nickel/core-ui/components/Tag/types'

import { getOpeningTag } from './domain/domain'

import { useLangCountry } from 'src/hooks/useLangCountry'
import { OpeningDay } from 'src/services/annuaire-buraliste-api-v3'

export const OpeningTag = ({ openingDays, timezone }: { openingDays: OpeningDay[]; timezone?: string }) => {
    const { t } = useTranslation('home')
    const { country, lang } = useLangCountry()

    const tagStatus = getOpeningTag(openingDays, lang, country, t, timezone)

    return <Tag state={tagStatus.state} text={tagStatus.text} variant={TagVariant.GHOST} />
}
