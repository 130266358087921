import React from 'react'

import { coordinatesQueryParams } from 'src/features/MapWrapper/domain/domain'
import { PageResponseStores } from 'src/services/annuaire-buraliste-api-v3'

export const fillSearchList = (
    isFetching: boolean,
    setStores: React.Dispatch<PageResponseStores | undefined>,
    stores: PageResponseStores | undefined,
    storesRequest: PageResponseStores | undefined
) => {
    if (storesRequest && !isFetching) {
        if (storesRequest.paging.number !== undefined && storesRequest.paging.number > 1 && stores !== undefined) {
            setStores({ ...storesRequest, content: [...stores.content, ...storesRequest.content] })
        } else {
            setStores(storesRequest)
        }
    }
}

export const getUrlToBackWithCoordinates = (searchParams: URLSearchParams) => {
    const coord = coordinatesQueryParams(searchParams)
    return coord ? `x=${coord.x}&y=${coord.y}&z=${coord.z}` : coord
}

export const STORES_PAGE_SIZE = 20
