import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { FiltersType, UseCoordinates } from '../types'

import { useLangCountry } from 'src/hooks/useLangCountry'
import { storeLocatorV3Api } from 'src/services'
import { Country } from 'src/services/annuaire-buraliste-api-v3'

const usePosCoordinates: UseCoordinates = (filters: FiltersType | undefined) => {
    const { country } = useLangCountry()

    const query = async () => {
        const makeRequest = await storeLocatorV3Api.getAllStoresCoordinates(country as Country, filters?.onlyOpened)
        return makeRequest()
    }

    const { data, isFetching, error } = useQuery(['getAllStoresCoordinates', { filters }], query)

    const stores = useMemo(() => data?.data || [], [data])

    return {
        isFetching,
        error,
        stores
    }
}

export default usePosCoordinates
