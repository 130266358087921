import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { UseAddressPredictions } from './types'

import { useLangCountry } from 'src/hooks/useLangCountry'
import { locationApi } from 'src/services'

const useAddressPredictions: UseAddressPredictions = (sessionToken: string, searchAddress: string) => {
    const { country, lang } = useLangCountry()

    const query = async () => {
        if (searchAddress.length < 3) return null
        const makeRequest = await locationApi.getAddressPredictions(sessionToken, searchAddress, country, lang, 'ALL')
        return makeRequest()
    }
    const { data, error, isFetching } = useQuery(['addressPredictions', { country, lang, searchAddress }], query, {
        enabled: searchAddress.length >= 3,
        refetchOnWindowFocus: false,
        retry: false
    })

    const predictionResults = useMemo(() => data?.data?.predictions || [], [data])

    return {
        predictionResults,
        error,
        isFetching
    }
}

export default useAddressPredictions
