import { pointHasMoved, viewportHasMoved } from '../components/Map/map.domain'
import { closeMarkerSvg, defaultMarkerSvg, openMarkerSvg } from '../components/utils'
import { ReloadProps } from '../types'

import { StoreOpeningStatus } from 'src/services/annuaire-buraliste-api-v3'

export const MIN_ZOOM_STORES = 13

export const getDefaultCenter = (country?: string) => {
    switch (country) {
        case 'FR':
            return { lat: 46.71109, lng: 1.7191036 }
        case 'ES':
            return { lat: 40.2085, lng: -3.713 }
        case 'PT':
            return { lat: 39.557191, lng: -7.8536599 }
        case 'DE':
            return { lat: 51.1642292, lng: 10.4541194 }
        case 'BE':
            return { lat: 50.5010789, lng: 4.4764595 }
        default:
            return { lat: 46.71109, lng: 1.7191036 }
    }
}

export const getIcon = (status?: StoreOpeningStatus) => {
    switch (status) {
        case StoreOpeningStatus.OPENED:
            return openMarkerSvg
        case StoreOpeningStatus.CLOSED:
            return closeMarkerSvg
        default:
            return defaultMarkerSvg
    }
}

export const reloadButtonVisible = ({ boundingBox, isDesktop, searchPosition, zoom }: ReloadProps) => {
    if (!isDesktop) {
        return false
    }

    return (
        zoom >= MIN_ZOOM_STORES &&
        (pointHasMoved(boundingBox, searchPosition?.point) || viewportHasMoved(boundingBox, searchPosition?.viewport))
    )
}

export const isValidCoordinate = (searchParam: string | null) =>
    searchParam !== null && searchParam.trim() !== '' && !Number.isNaN(Number(searchParam))

export const coordinatesQueryParams = (searchParams?: URLSearchParams) => {
    if (searchParams) {
        const x = searchParams.get('x')
        const y = searchParams.get('y')
        const z = searchParams.get('z')

        if (isValidCoordinate(x) && isValidCoordinate(y) && isValidCoordinate(z)) {
            return {
                x: Number(x),
                y: Number(y),
                z: Number(z)
            }
        }
    }
    return undefined
}

export const getDefaultZoom = (country?: string) => {
    switch (country) {
        case 'PT':
            return 7
        case 'BE':
            return 8
        case 'FR':
        case 'ES':
        case 'DE':
        default:
            return 6
    }
}
