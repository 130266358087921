/* tslint:disable */
/* eslint-disable */
/**
 * Store Locator V3
 * Service V3 for Store Locator
 *
 * The version of the OpenAPI document: 3.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction
} from './common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface Address
 */
export interface Address {
    /**
     *
     * @type {string}
     * @memberof Address
     */
    address?: string
    /**
     *
     * @type {string}
     * @memberof Address
     */
    zipCode?: string
    /**
     *
     * @type {string}
     * @memberof Address
     */
    city?: string
    /**
     *
     * @type {Country}
     * @memberof Address
     */
    country?: Country
    /**
     *
     * @type {string}
     * @memberof Address
     */
    timezone?: string
}
/**
 *
 * @export
 * @interface CoordinateStore
 */
export interface CoordinateStore {
    /**
     *
     * @type {string}
     * @memberof CoordinateStore
     */
    id: string
    /**
     *
     * @type {Coordinates}
     * @memberof CoordinateStore
     */
    coordinates: Coordinates
    /**
     *
     * @type {StoreOpeningStatus}
     * @memberof CoordinateStore
     */
    state: StoreOpeningStatus
}
/**
 *
 * @export
 * @interface Coordinates
 */
export interface Coordinates {
    /**
     *
     * @type {number}
     * @memberof Coordinates
     */
    latitude: number
    /**
     *
     * @type {number}
     * @memberof Coordinates
     */
    longitude: number
}
/**
 *
 * @export
 * @enum {string}
 */
export enum Country {
    FR = 'FR',
    ES = 'ES',
    PT = 'PT',
    BE = 'BE',
    DE = 'DE'
}

/**
 *
 * @export
 * @interface LightStore
 */
export interface LightStore {
    /**
     *
     * @type {string}
     * @memberof LightStore
     */
    id: string
    /**
     *
     * @type {string}
     * @memberof LightStore
     */
    name: string
    /**
     *
     * @type {Address}
     * @memberof LightStore
     */
    address: Address
    /**
     *
     * @type {StoreOpeningStatus}
     * @memberof LightStore
     */
    state?: StoreOpeningStatus
    /**
     *
     * @type {Array<OpeningDay>}
     * @memberof LightStore
     */
    openingDays: Array<OpeningDay>
    /**
     *
     * @type {Coordinates}
     * @memberof LightStore
     */
    coordinates: Coordinates
    /**
     *
     * @type {number}
     * @memberof LightStore
     */
    distance?: number
}
/**
 *
 * @export
 * @interface Links
 */
export interface Links {
    /**
     *
     * @type {string}
     * @memberof Links
     */
    first?: string
    /**
     *
     * @type {string}
     * @memberof Links
     */
    last?: string
    /**
     *
     * @type {string}
     * @memberof Links
     */
    prev?: string
    /**
     *
     * @type {string}
     * @memberof Links
     */
    next?: string
    /**
     *
     * @type {string}
     * @memberof Links
     */
    self?: string
}
/**
 *
 * @export
 * @interface OpeningDay
 */
export interface OpeningDay {
    /**
     *
     * @type {number}
     * @memberof OpeningDay
     */
    day: number
    /**
     *
     * @type {Array<OpeningHour>}
     * @memberof OpeningDay
     */
    openingHours: Array<OpeningHour>
}
/**
 *
 * @export
 * @interface OpeningHour
 */
export interface OpeningHour {
    /**
     *
     * @type {string}
     * @memberof OpeningHour
     */
    start: string
    /**
     *
     * @type {string}
     * @memberof OpeningHour
     */
    end: string
}
/**
 *
 * @export
 * @interface PageResponseStores
 */
export interface PageResponseStores {
    /**
     *
     * @type {Array<LightStore>}
     * @memberof PageResponseStores
     */
    content: Array<LightStore>
    /**
     *
     * @type {Paging}
     * @memberof PageResponseStores
     */
    paging: Paging
    /**
     *
     * @type {Links}
     * @memberof PageResponseStores
     */
    links: Links
}
/**
 *
 * @export
 * @interface Paging
 */
export interface Paging {
    /**
     *
     * @type {number}
     * @memberof Paging
     */
    number?: number
    /**
     *
     * @type {number}
     * @memberof Paging
     */
    limitPerPage?: number
    /**
     *
     * @type {number}
     * @memberof Paging
     */
    totalPages?: number
    /**
     *
     * @type {number}
     * @memberof Paging
     */
    totalElements?: number
}
/**
 * This problem can contain additional information.
 * @export
 * @interface Problem
 */
export interface Problem {
    /**
     *
     * @type {string}
     * @memberof Problem
     */
    title: string
    /**
     *
     * @type {number}
     * @memberof Problem
     */
    status: number
    /**
     *
     * @type {string}
     * @memberof Problem
     */
    detail?: string
    /**
     *
     * @type {string}
     * @memberof Problem
     */
    correlationId?: string
    /**
     *
     * @type {StoreError}
     * @memberof Problem
     */
    errorCode?: StoreError
    /**
     *
     * @type {Array<ProblemParam>}
     * @memberof Problem
     */
    params?: Array<ProblemParam>
}
/**
 *
 * @export
 * @interface ProblemParam
 */
export interface ProblemParam {
    /**
     *
     * @type {string}
     * @memberof ProblemParam
     */
    key?: string
    /**
     *
     * @type {object}
     * @memberof ProblemParam
     */
    value?: object
}
/**
 *
 * @export
 * @interface Store
 */
export interface Store {
    /**
     *
     * @type {string}
     * @memberof Store
     */
    id: string
    /**
     *
     * @type {string}
     * @memberof Store
     */
    name: string
    /**
     *
     * @type {Address}
     * @memberof Store
     */
    address?: Address
    /**
     *
     * @type {Array<OpeningDay>}
     * @memberof Store
     */
    openingDays?: Array<OpeningDay>
    /**
     *
     * @type {Coordinates}
     * @memberof Store
     */
    coordinates: Coordinates
}
/**
 *
 * @export
 * @interface StoreCount
 */
export interface StoreCount {
    /**
     *
     * @type {number}
     * @memberof StoreCount
     */
    count?: number
}
/**
 *
 * @export
 * @enum {string}
 */
export enum StoreError {
    STORE_NOT_FOUND = 'STORE_NOT_FOUND',
    STORES_BAD_PARAM = 'STORES_BAD_PARAM'
}

/**
 *
 * @export
 * @enum {string}
 */
export enum StoreOpeningStatus {
    UNKNOWN = 'UNKNOWN',
    OPENED = 'OPENED',
    CLOSED = 'CLOSED'
}

/**
 *
 * @export
 * @interface StoreVisibility
 */
export interface StoreVisibility {
    /**
     *
     * @type {boolean}
     * @memberof StoreVisibility
     */
    visible: boolean
    /**
     *
     * @type {VisibilityDetails}
     * @memberof StoreVisibility
     */
    details: VisibilityDetails
}
/**
 *
 * @export
 * @interface VisibilityDetails
 */
export interface VisibilityDetails {
    /**
     *
     * @type {boolean}
     * @memberof VisibilityDetails
     */
    certified: boolean
    /**
     *
     * @type {boolean}
     * @memberof VisibilityDetails
     */
    hasHardware: boolean
    /**
     *
     * @type {boolean}
     * @memberof VisibilityDetails
     */
    hasFirstActivation: boolean
    /**
     *
     * @type {boolean}
     * @memberof VisibilityDetails
     */
    hasCoordinates: boolean
}

/**
 * StoreLocatorV3Api - axios parameter creator
 * @export
 */
export const StoreLocatorV3ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get all store\'s coordinates by country
         * @param {Country} country Country ISO2
         * @param {boolean} [open] Criteria to display only opened stores
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStoresCoordinates: async (country: Country, open?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'country' is not null or undefined
            assertParamExists('getAllStoresCoordinates', 'country', country)
            const localVarPath = `/stores/coordinates`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (country !== undefined) {
                localVarQueryParameter['country'] = country
            }

            if (open !== undefined) {
                localVarQueryParameter['open'] = open
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions
            }
        },
        /**
         *
         * @summary Get lightstore with an Id
         * @param {string} id Store searched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLightStoreById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLightStoreById', 'id', id)
            const localVarPath = `/stores/{id}/light`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions
            }
        },
        /**
         *
         * @summary Get store with an Id
         * @param {string} posId Store searched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreById: async (posId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'posId' is not null or undefined
            assertParamExists('getStoreById', 'posId', posId)
            const localVarPath = `/stores/{posId}`.replace(`{${'posId'}}`, encodeURIComponent(String(posId)))
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions
            }
        },
        /**
         *
         * @summary Get store visibility with an Id
         * @param {string} posId Store searched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreVisibilityById: async (posId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'posId' is not null or undefined
            assertParamExists('getStoreVisibilityById', 'posId', posId)
            const localVarPath = `/stores/{posId}/visible`.replace(`{${'posId'}}`, encodeURIComponent(String(posId)))
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions
            }
        },
        /**
         *
         * @summary Get list of stores
         * @param {string} country
         * @param {number} pageNumber
         * @param {number} [southWestLatitude] South west latitude
         * @param {number} [southWestLongitude] South west longitude
         * @param {number} [northEastLatitude] North east latitude
         * @param {number} [northEastLongitude] North east longitude
         * @param {number} [centerLatitude] Latitude of a point to compute distance
         * @param {number} [centerLongitude] Longitude of a point to compute distance
         * @param {number} [limitPerPage]
         * @param {boolean} [open] Criteria to display only opened stores
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStores: async (
            country: string,
            pageNumber: number,
            southWestLatitude?: number,
            southWestLongitude?: number,
            northEastLatitude?: number,
            northEastLongitude?: number,
            centerLatitude?: number,
            centerLongitude?: number,
            limitPerPage?: number,
            open?: boolean,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'country' is not null or undefined
            assertParamExists('getStores', 'country', country)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getStores', 'pageNumber', pageNumber)
            const localVarPath = `/stores`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (country !== undefined) {
                localVarQueryParameter['country'] = country
            }

            if (southWestLatitude !== undefined) {
                localVarQueryParameter['southWestLatitude'] = southWestLatitude
            }

            if (southWestLongitude !== undefined) {
                localVarQueryParameter['southWestLongitude'] = southWestLongitude
            }

            if (northEastLatitude !== undefined) {
                localVarQueryParameter['northEastLatitude'] = northEastLatitude
            }

            if (northEastLongitude !== undefined) {
                localVarQueryParameter['northEastLongitude'] = northEastLongitude
            }

            if (centerLatitude !== undefined) {
                localVarQueryParameter['centerLatitude'] = centerLatitude
            }

            if (centerLongitude !== undefined) {
                localVarQueryParameter['centerLongitude'] = centerLongitude
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber
            }

            if (limitPerPage !== undefined) {
                localVarQueryParameter['limitPerPage'] = limitPerPage
            }

            if (open !== undefined) {
                localVarQueryParameter['open'] = open
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions
            }
        },
        /**
         *
         * @summary Get stores count
         * @param {Country} [country] Country ISO2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoresCount: async (country?: Country, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/stores/count`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (country !== undefined) {
                localVarQueryParameter['country'] = country
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions
            }
        }
    }
}

/**
 * StoreLocatorV3Api - functional programming interface
 * @export
 */
export const StoreLocatorV3ApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = StoreLocatorV3ApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Get all store\'s coordinates by country
         * @param {Country} country Country ISO2
         * @param {boolean} [open] Criteria to display only opened stores
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllStoresCoordinates(
            country: Country,
            open?: boolean,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CoordinateStore>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllStoresCoordinates(country, open, options)
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
        },
        /**
         *
         * @summary Get lightstore with an Id
         * @param {string} id Store searched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLightStoreById(
            id: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LightStore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLightStoreById(id, options)
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
        },
        /**
         *
         * @summary Get store with an Id
         * @param {string} posId Store searched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreById(
            posId: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Store>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreById(posId, options)
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
        },
        /**
         *
         * @summary Get store visibility with an Id
         * @param {string} posId Store searched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreVisibilityById(
            posId: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreVisibility>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreVisibilityById(posId, options)
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
        },
        /**
         *
         * @summary Get list of stores
         * @param {string} country
         * @param {number} pageNumber
         * @param {number} [southWestLatitude] South west latitude
         * @param {number} [southWestLongitude] South west longitude
         * @param {number} [northEastLatitude] North east latitude
         * @param {number} [northEastLongitude] North east longitude
         * @param {number} [centerLatitude] Latitude of a point to compute distance
         * @param {number} [centerLongitude] Longitude of a point to compute distance
         * @param {number} [limitPerPage]
         * @param {boolean} [open] Criteria to display only opened stores
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStores(
            country: string,
            pageNumber: number,
            southWestLatitude?: number,
            southWestLongitude?: number,
            northEastLatitude?: number,
            northEastLongitude?: number,
            centerLatitude?: number,
            centerLongitude?: number,
            limitPerPage?: number,
            open?: boolean,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseStores>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStores(
                country,
                pageNumber,
                southWestLatitude,
                southWestLongitude,
                northEastLatitude,
                northEastLongitude,
                centerLatitude,
                centerLongitude,
                limitPerPage,
                open,
                options
            )
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
        },
        /**
         *
         * @summary Get stores count
         * @param {Country} [country] Country ISO2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoresCount(
            country?: Country,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoresCount(country, options)
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
        }
    }
}

/**
 * StoreLocatorV3Api - factory interface
 * @export
 */
export const StoreLocatorV3ApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = StoreLocatorV3ApiFp(configuration)
    return {
        /**
         *
         * @summary Get all store\'s coordinates by country
         * @param {Country} country Country ISO2
         * @param {boolean} [open] Criteria to display only opened stores
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStoresCoordinates(country: Country, open?: boolean, options?: any): AxiosPromise<Array<CoordinateStore>> {
            return localVarFp
                .getAllStoresCoordinates(country, open, options)
                .then((request) => request(axios, basePath))
        },
        /**
         *
         * @summary Get lightstore with an Id
         * @param {string} id Store searched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLightStoreById(id: string, options?: any): AxiosPromise<LightStore> {
            return localVarFp.getLightStoreById(id, options).then((request) => request(axios, basePath))
        },
        /**
         *
         * @summary Get store with an Id
         * @param {string} posId Store searched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreById(posId: string, options?: any): AxiosPromise<Store> {
            return localVarFp.getStoreById(posId, options).then((request) => request(axios, basePath))
        },
        /**
         *
         * @summary Get store visibility with an Id
         * @param {string} posId Store searched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreVisibilityById(posId: string, options?: any): AxiosPromise<StoreVisibility> {
            return localVarFp.getStoreVisibilityById(posId, options).then((request) => request(axios, basePath))
        },
        /**
         *
         * @summary Get list of stores
         * @param {string} country
         * @param {number} pageNumber
         * @param {number} [southWestLatitude] South west latitude
         * @param {number} [southWestLongitude] South west longitude
         * @param {number} [northEastLatitude] North east latitude
         * @param {number} [northEastLongitude] North east longitude
         * @param {number} [centerLatitude] Latitude of a point to compute distance
         * @param {number} [centerLongitude] Longitude of a point to compute distance
         * @param {number} [limitPerPage]
         * @param {boolean} [open] Criteria to display only opened stores
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStores(
            country: string,
            pageNumber: number,
            southWestLatitude?: number,
            southWestLongitude?: number,
            northEastLatitude?: number,
            northEastLongitude?: number,
            centerLatitude?: number,
            centerLongitude?: number,
            limitPerPage?: number,
            open?: boolean,
            options?: any
        ): AxiosPromise<PageResponseStores> {
            return localVarFp
                .getStores(
                    country,
                    pageNumber,
                    southWestLatitude,
                    southWestLongitude,
                    northEastLatitude,
                    northEastLongitude,
                    centerLatitude,
                    centerLongitude,
                    limitPerPage,
                    open,
                    options
                )
                .then((request) => request(axios, basePath))
        },
        /**
         *
         * @summary Get stores count
         * @param {Country} [country] Country ISO2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoresCount(country?: Country, options?: any): AxiosPromise<StoreCount> {
            return localVarFp.getStoresCount(country, options).then((request) => request(axios, basePath))
        }
    }
}

/**
 * StoreLocatorV3Api - object-oriented interface
 * @export
 * @class StoreLocatorV3Api
 * @extends {BaseAPI}
 */
export class StoreLocatorV3Api extends BaseAPI {
    /**
     *
     * @summary Get all store\'s coordinates by country
     * @param {Country} country Country ISO2
     * @param {boolean} [open] Criteria to display only opened stores
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreLocatorV3Api
     */
    public getAllStoresCoordinates(country: Country, open?: boolean, options?: any) {
        return StoreLocatorV3ApiFp(this.configuration)
            .getAllStoresCoordinates(country, open, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     *
     * @summary Get lightstore with an Id
     * @param {string} id Store searched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreLocatorV3Api
     */
    public getLightStoreById(id: string, options?: any) {
        return StoreLocatorV3ApiFp(this.configuration)
            .getLightStoreById(id, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     *
     * @summary Get store with an Id
     * @param {string} posId Store searched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreLocatorV3Api
     */
    public getStoreById(posId: string, options?: any) {
        return StoreLocatorV3ApiFp(this.configuration)
            .getStoreById(posId, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     *
     * @summary Get store visibility with an Id
     * @param {string} posId Store searched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreLocatorV3Api
     */
    public getStoreVisibilityById(posId: string, options?: any) {
        return StoreLocatorV3ApiFp(this.configuration)
            .getStoreVisibilityById(posId, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     *
     * @summary Get list of stores
     * @param {string} country
     * @param {number} pageNumber
     * @param {number} [southWestLatitude] South west latitude
     * @param {number} [southWestLongitude] South west longitude
     * @param {number} [northEastLatitude] North east latitude
     * @param {number} [northEastLongitude] North east longitude
     * @param {number} [centerLatitude] Latitude of a point to compute distance
     * @param {number} [centerLongitude] Longitude of a point to compute distance
     * @param {number} [limitPerPage]
     * @param {boolean} [open] Criteria to display only opened stores
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreLocatorV3Api
     */
    public getStores(
        country: string,
        pageNumber: number,
        southWestLatitude?: number,
        southWestLongitude?: number,
        northEastLatitude?: number,
        northEastLongitude?: number,
        centerLatitude?: number,
        centerLongitude?: number,
        limitPerPage?: number,
        open?: boolean,
        options?: any
    ) {
        return StoreLocatorV3ApiFp(this.configuration)
            .getStores(
                country,
                pageNumber,
                southWestLatitude,
                southWestLongitude,
                northEastLatitude,
                northEastLongitude,
                centerLatitude,
                centerLongitude,
                limitPerPage,
                open,
                options
            )
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     *
     * @summary Get stores count
     * @param {Country} [country] Country ISO2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreLocatorV3Api
     */
    public getStoresCount(country?: Country, options?: any) {
        return StoreLocatorV3ApiFp(this.configuration)
            .getStoresCount(country, options)
            .then((request) => request(this.axios, this.basePath))
    }
}
