import React, { KeyboardEvent, useCallback, useContext } from 'react'

import { useTranslation } from 'react-i18next'

import Card from '@nickel/core-ui/components/Card'
import { IconSize } from '@nickel/core-ui/components/Icons/types'

import { OpeningTag } from '../OpeningTag/OpeningTag'

import { getDistance } from './domain/domain'
import { LinkButton, Title, UnpinButton, Wrapper } from './styles'

import { AppContext } from 'src/context'
import { AppActionsType } from 'src/context/types'
import { LightStore } from 'src/services/annuaire-buraliste-api-v3'
import { WhiteButton } from 'src/styles'
import { getItineraryLink } from 'src/utils/links'
import { SnowplowAction, trackClickEvent } from 'src/utils/Tracking/utils'

export const PosCard = ({
    isMobileFullScreen,
    saveSearchAndNavigate,
    selected,
    store
}: {
    isMobileFullScreen?: boolean
    saveSearchAndNavigate: (storeId: string) => void
    selected?: boolean
    store: LightStore
}) => {
    const { t } = useTranslation('home')
    const { dispatch } = useContext(AppContext)

    const _onClick = () => {
        if (!selected) {
            trackClickEvent(SnowplowAction.STORE_CLICK_SEARCH_PANEL)
            dispatch({
                type: AppActionsType.SET_STORE_PINNED_ID,
                payload: store.id
            })
            if (!isMobileFullScreen) {
                dispatch({
                    type: AppActionsType.SET_MAP_POSITION,
                    payload: {
                        point: {
                            lat: store.coordinates.latitude,
                            lng: store.coordinates.longitude
                        }
                    }
                })
            }
        }
    }

    const _onKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') _onClick()
    }

    const _onUnpin = (e: React.MouseEvent) => {
        trackClickEvent(SnowplowAction.UNPIN_POS)
        e.stopPropagation()
        dispatch({
            type: AppActionsType.SET_STORE_PINNED_ID,
            payload: undefined
        })
    }

    const _onCLickMoreInfo = () => {
        trackClickEvent(selected ? SnowplowAction.STORE_MORE_INFO_PINED : SnowplowAction.STORE_MORE_INFO_SEARCH_PANEL)
        saveSearchAndNavigate(store.id)
    }

    const _getDistance = useCallback(() => getDistance(store.distance, t), [store.distance, t])

    const _onItineraryClick = () => {
        trackClickEvent(selected ? SnowplowAction.STORE_ITINERARY_PINED : SnowplowAction.STORE_ITINERARY_SEARCH_PANEL)
    }

    return (
        <div
            data-testid="posCard"
            id={`storePopUp#${store.id}`}
            onClick={_onClick}
            onKeyDown={_onKeyDown}
            role="tablist"
            tabIndex={0}
        >
            <Wrapper
                className="posCard"
                isMobileFullScreen={isMobileFullScreen}
                selected={selected}
                testId={`storePopUp#${store.id}${selected ? '#selected' : ''}`}
            >
                <Card.Header className="posCardHeader">
                    <Title>
                        {store.name}
                        {selected && (
                            <UnpinButton onClick={_onUnpin} data-testid="closePosCard" size={IconSize.SMALL} />
                        )}
                    </Title>
                    <OpeningTag openingDays={store.openingDays ?? []} timezone={store.address?.timezone} />
                </Card.Header>
                <Card.Content className="posCardContent">
                    <div>
                        <span>{store.address?.address}</span>
                        <span>{`${store.address?.zipCode}  ${store.address?.city}`}</span>
                    </div>
                    {store.distance && <div data-testid="distance">{_getDistance()}</div>}
                </Card.Content>
                <Card.Footer className="posCardFooter">
                    <div
                        onClick={(event) => event.preventDefault()}
                        role="button"
                        tabIndex={-1}
                        onKeyUp={(event) => event.preventDefault()}
                    >
                        <WhiteButton
                            className="fixWidthButton"
                            onClick={_onCLickMoreInfo}
                            testId="moreDetailPos"
                            text={t('posCard.moreDetail')}
                            withBorder
                        />
                    </div>
                    <LinkButton
                        href={getItineraryLink(store.coordinates)}
                        target="_blank"
                        rel="noreferrer"
                        tabIndex={-1}
                    >
                        <WhiteButton
                            className="fixWidthButton"
                            onClick={_onItineraryClick}
                            testId="itinerary"
                            text={t('posCard.itinerary')}
                            withBorder
                        />
                    </LinkButton>
                </Card.Footer>
            </Wrapper>
        </div>
    )
}
