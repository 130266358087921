import errors from './errors.json'
import home from './home.json'
import infos from './infos.json'
import specificities from './specificities.json'

export default {
    errors,
    home,
    infos,
    specificities
}
