import styled from 'styled-components'

import { References, Systems } from '@nickel/foundations/tokens/color'
import { Breakpoint, Spacing } from '@nickel/foundations/tokens/layout'
import { FontSize } from '@nickel/foundations/tokens/typography'

import { HeaderSearchBar } from '../SearchBar/styles'

export const MapContainer = styled.div`
    display: block;
    height: calc(100% - ${HeaderSearchBar});
    left: 0;
    position: fixed;
    top: ${HeaderSearchBar};
    width: 100vw;

    @media (min-width: ${Breakpoint.MEDIUM}) {
        display: block;
        flex: 1 1 60%;
        height: unset;
        min-height: 70vh;
        overflow: hidden;
        position: unset;
        width: unset;
    }
`

export const GoogleMap = styled.div`
    height: 100%;
    width: 100%;
`

export const ErrorLoader = styled.div`
    align-items: center;
    background: ${Systems.SYS_FEEDBACK_ERROR_BACKGROUND};
    color: ${Systems.SYS_FEEDBACK_ERROR_TEXT};
    display: flex;
    flex-direction: column;
    font-size: ${FontSize.XXLARGE};
    gap: ${Spacing.MEDIUM};
    height: 100%;
    justify-content: center;
    padding: ${Spacing.XLARGE};
    width: 100%;
`

export const Chips = styled.div`
    align-items: center;
    display: flex;
    gap: ${Spacing.XLARGE};
    justify-content: flex-start;
`

export const MapHoverActions = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: ${Spacing.XLARGE};
    margin-top: ${Spacing.XLARGE};
    position: absolute;
    z-index: 1;
`

export const Chip = styled.div`
    align-items: center;
    background-color: ${References.REF_NEUTRAL_N0};
    border-radius: 16px;
    display: flex;
    gap: ${Spacing.SMALL};
    margin-bottom: ${Spacing.XLARGE};
    padding: ${Spacing.MEDIUM} ${Spacing.LARGE};
    position: relative;
    text-align: center;
    width: fit-content;

    &:hover {
        cursor: pointer;
    }
`
