import { TrackerConfiguration } from '@snowplow/browser-tracker'

export const snowplow: TrackerConfiguration = {
    appId: window.REACT_APP_SNOWPLOW_TRACKER_APP_ID,
    platform: 'web',
    cookieDomain: undefined,
    discoverRootDomain: true,
    cookieName: '_storeLocator_', // pour eviter les adblock on ne fait pas _sp_
    cookieSameSite: 'Lax', // Recommended
    cookieSecure: true,
    encodeBase64: true,
    respectDoNotTrack: true,
    eventMethod: 'post',
    bufferSize: 1,
    maxPostBytes: 40000,
    maxGetBytes: 1000,
    cookieLifetime: 33696000, // 13 mois de storage
    stateStorageStrategy: 'cookieAndLocalStorage',
    maxLocalStorageQueueSize: 1000,
    resetActivityTrackingOnPageView: true,
    connectionTimeout: 5000,
    anonymousTracking: false,
    withCredentials: false,

    contexts: {
        session: true,
        webPage: true,
        browser: true
    }
}
