import { QueryClient } from '@tanstack/react-query'

export const queryConfig = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false
        }
    }
})
