import { References } from '@nickel/foundations/tokens/color'

export const CLUSTER_LABEL_KEY = '%COUNT%'
const CLUSTER_SIZE_ICON = 46
const LOCATION_SIZE_ICON = 35

export const defaultMarkerSvg = `<svg width="30" height="43" viewBox="0 0 30 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15 0C6.71571 0 0 6.7381 0 15.05C0 23.3619 15 43 15 43C15 43 30 23.3619 30 15.05C30 6.7381 23.2843 0 15 0Z" fill="#FF5F00"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15 10.8281C15.7812 10.8281 16.416 10.1973 16.416 9.41602C16.4141 8.63867 15.7793 8 15 8C14.2207 8 13.5859 8.63867 13.5859 9.41602C13.5859 10.1973 14.2207 10.8281 15 10.8281ZM19.0488 16.6777H10.9531C10.1699 16.6777 9.53711 17.3027 9.53711 18.0859C9.53711 18.8633 10.1719 19.4902 10.9531 19.4902H19.0508C19.8301 19.4902 20.4668 18.8633 20.4668 18.0859C20.4648 17.3027 19.8281 16.6777 19.0488 16.6777ZM13.1641 20.8984H16.8398C17.6191 20.8984 18.25 21.5352 18.248 22.3105C18.248 23.0879 17.6172 23.7207 16.8398 23.7207L16.8359 23.7129H13.1602C12.3848 23.7129 11.752 23.0781 11.752 22.3008C11.752 21.5234 12.3887 20.8984 13.1641 20.8984ZM16.8398 12.375H13.1641C12.3887 12.375 11.752 13 11.752 13.7754C11.752 14.5566 12.3848 15.1875 13.1602 15.1875H16.8359L16.8398 15.1895C17.6172 15.1895 18.248 14.5605 18.248 13.7832C18.25 13.0078 17.6191 12.375 16.8398 12.375ZM13.5859 26.5859C13.5859 25.8066 14.2207 25.1699 15 25.1699C15.7793 25.1699 16.4141 25.8066 16.416 26.5859C16.416 27.3672 15.7812 28 15 28C14.2227 28 13.5859 27.3672 13.5859 26.5859Z" fill="white"/>
</svg>
`

export const defaultMarkerClickedSvg = `<svg width="30" height="43" viewBox="0 0 30 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15 0C6.71571 0 0 6.7381 0 15.05C0 23.3619 15 43 15 43C15 43 30 23.3619 30 15.05C30 6.7381 23.2843 0 15 0Z" fill="#1F61D4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15 10.8281C15.7812 10.8281 16.416 10.1973 16.416 9.41602C16.4141 8.63867 15.7793 8 15 8C14.2207 8 13.5859 8.63867 13.5859 9.41602C13.5859 10.1973 14.2207 10.8281 15 10.8281ZM19.0488 16.6777H10.9531C10.1699 16.6777 9.53711 17.3027 9.53711 18.0859C9.53711 18.8633 10.1719 19.4902 10.9531 19.4902H19.0508C19.8301 19.4902 20.4668 18.8633 20.4668 18.0859C20.4648 17.3027 19.8281 16.6777 19.0488 16.6777ZM13.1641 20.8984H16.8398C17.6191 20.8984 18.25 21.5352 18.248 22.3105C18.248 23.0879 17.6172 23.7207 16.8398 23.7207L16.8359 23.7129H13.1602C12.3848 23.7129 11.752 23.0781 11.752 22.3008C11.752 21.5234 12.3887 20.8984 13.1641 20.8984ZM16.8398 12.375H13.1641C12.3887 12.375 11.752 13 11.752 13.7754C11.752 14.5566 12.3848 15.1875 13.1602 15.1875H16.8359L16.8398 15.1895C17.6172 15.1895 18.248 14.5605 18.248 13.7832C18.25 13.0078 17.6191 12.375 16.8398 12.375ZM13.5859 26.5859C13.5859 25.8066 14.2207 25.1699 15 25.1699C15.7793 25.1699 16.4141 25.8066 16.416 26.5859C16.416 27.3672 15.7812 28 15 28C14.2227 28 13.5859 27.3672 13.5859 26.5859Z" fill="white"/>
</svg>
`

export const openMarkerSvg = `<svg width="32" height="45" viewBox="0 0 32 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15 2C6.71571 2 0 8.7381 0 17.05C0 25.3619 15 45 15 45C15 45 30 25.3619 30 17.05C30 8.7381 23.2843 2 15 2Z" fill="#FF5F00"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15 12.8281C15.7812 12.8281 16.416 12.1973 16.416 11.416C16.4141 10.6387 15.7793 10 15 10C14.2207 10 13.5859 10.6387 13.5859 11.416C13.5859 12.1973 14.2207 12.8281 15 12.8281ZM19.0488 18.6777H10.9531C10.1699 18.6777 9.53711 19.3027 9.53711 20.0859C9.53711 20.8633 10.1719 21.4902 10.9531 21.4902H19.0508C19.8301 21.4902 20.4668 20.8633 20.4668 20.0859C20.4648 19.3027 19.8281 18.6777 19.0488 18.6777ZM13.1641 22.8984H16.8398C17.6191 22.8984 18.25 23.5352 18.248 24.3105C18.248 25.0879 17.6172 25.7207 16.8398 25.7207L16.8359 25.7129H13.1602C12.3848 25.7129 11.752 25.0781 11.752 24.3008C11.752 23.5234 12.3887 22.8984 13.1641 22.8984ZM16.8398 14.375H13.1641C12.3887 14.375 11.752 15 11.752 15.7754C11.752 16.5566 12.3848 17.1875 13.1602 17.1875H16.8359L16.8398 17.1895C17.6172 17.1895 18.248 16.5605 18.248 15.7832C18.25 15.0078 17.6191 14.375 16.8398 14.375ZM13.5859 28.5859C13.5859 27.8066 14.2207 27.1699 15 27.1699C15.7793 27.1699 16.4141 27.8066 16.416 28.5859C16.416 29.3672 15.7812 30 15 30C14.2227 30 13.5859 29.3672 13.5859 28.5859Z" fill="white"/>
<circle cx="26" cy="6" r="6" fill="#1F61D4"/>
</svg>
`

export const closeMarkerSvg = `<svg width="32" height="45" viewBox="0 0 32 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15 2C6.71571 2 0 8.7381 0 17.05C0 25.3619 15 45 15 45C15 45 30 25.3619 30 17.05C30 8.7381 23.2843 2 15 2Z" fill="#FF5F00"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15 12.8281C15.7812 12.8281 16.416 12.1973 16.416 11.416C16.4141 10.6387 15.7793 10 15 10C14.2207 10 13.5859 10.6387 13.5859 11.416C13.5859 12.1973 14.2207 12.8281 15 12.8281ZM19.0488 18.6777H10.9531C10.1699 18.6777 9.53711 19.3027 9.53711 20.0859C9.53711 20.8633 10.1719 21.4902 10.9531 21.4902H19.0508C19.8301 21.4902 20.4668 20.8633 20.4668 20.0859C20.4648 19.3027 19.8281 18.6777 19.0488 18.6777ZM13.1641 22.8984H16.8398C17.6191 22.8984 18.25 23.5352 18.248 24.3105C18.248 25.0879 17.6172 25.7207 16.8398 25.7207L16.8359 25.7129H13.1602C12.3848 25.7129 11.752 25.0781 11.752 24.3008C11.752 23.5234 12.3887 22.8984 13.1641 22.8984ZM16.8398 14.375H13.1641C12.3887 14.375 11.752 15 11.752 15.7754C11.752 16.5566 12.3848 17.1875 13.1602 17.1875H16.8359L16.8398 17.1895C17.6172 17.1895 18.248 16.5605 18.248 15.7832C18.25 15.0078 17.6191 14.375 16.8398 14.375ZM13.5859 28.5859C13.5859 27.8066 14.2207 27.1699 15 27.1699C15.7793 27.1699 16.4141 27.8066 16.416 28.5859C16.416 29.3672 15.7812 30 15 30C14.2227 30 13.5859 29.3672 13.5859 28.5859Z" fill="white"/>
<circle cx="26" cy="6" r="6" fill="#D2180D"/>
</svg>`

export const defaultClusterSvg = `
  <svg fill="rgb(255,95,0)" width="${CLUSTER_SIZE_ICON}" height="${CLUSTER_SIZE_ICON}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${CLUSTER_SIZE_ICON} ${CLUSTER_SIZE_ICON}">
    <circle cx="${CLUSTER_SIZE_ICON / 2}" cy="${CLUSTER_SIZE_ICON / 2}" opacity=".8" r="${CLUSTER_SIZE_ICON / 2}" /> 
    <text x="50%" y="52%" dominant-baseline="middle" text-anchor="middle" font-size="12px" fill="${
        References.REF_NEUTRAL_N0
    }">${CLUSTER_LABEL_KEY}</text>
  </svg>`

export const currentPositionSvg = `<svg  width="${LOCATION_SIZE_ICON}" height="${LOCATION_SIZE_ICON}" viewBox="0 0 ${LOCATION_SIZE_ICON} ${LOCATION_SIZE_ICON}" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="${LOCATION_SIZE_ICON / 2}" cy="${LOCATION_SIZE_ICON / 2}" r="${
    LOCATION_SIZE_ICON / 2
}" fill="#DDE7F7" fill-opacity="0.9">
<animate attributeName="fill" values="#DDE7F7;#B5CCED;#DDE7F7" dur="2s" repeatCount="indefinite"></animate>
</circle>
<circle cx="${LOCATION_SIZE_ICON / 2}" cy="${LOCATION_SIZE_ICON / 2}" r="${
    LOCATION_SIZE_ICON / 3.75
}" fill="#4878ec" stroke="white" stroke-width="2"/>
</svg>
`

export const CLUSTERS_RADIUS = 160
export const MIN_ZOOM = 4
export const ZOOM_TO_PAN = 16
