/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { addLibTranslations as addLibCoreUiTranslations } from '@nickel/core-ui/lib/i18n'
import i18n, { addTranslations, Dictionnary, setHtmlLang } from '@nickel/i18n'
import { SupportedLocale } from '@nickel/i18n/lib/types'
import { addLibTranslations as addL10nTranslations } from '@nickel/l10n/lib/i18n'

import { getCurrentLanguageFromUrl } from '../utils/i18n'

import deDE from './languages/de_DE'
import enUS from './languages/en_US'
import esES from './languages/es_ES'
import frBE from './languages/fr_BE'
import frFR from './languages/fr_FR'
import nlBE from './languages/nl_BE'
import ptPT from './languages/pt_PT'
import root from './languages/root'

export const dictionnary: Dictionnary = {
    [SupportedLocale.DE_DE]: deDE,
    [SupportedLocale.EN_GB]: enUS,
    [SupportedLocale.EN_US]: enUS,
    [SupportedLocale.ES_ES]: esES,
    [SupportedLocale.FR_BE]: frBE,
    [SupportedLocale.FR_FR]: frFR,
    [SupportedLocale.NL_BE]: nlBE,
    [SupportedLocale.PT_PT]: ptPT,
    root
}

export const changeLanguageAllProvider = (locale: SupportedLocale) => {
    i18n.changeLanguage(locale)
    setHtmlLang(locale)
}

export const changeLanguageWithCulture = () => {
    const locale = getCurrentLanguageFromUrl(window.location.pathname) || SupportedLocale.FR_FR
    changeLanguageAllProvider(locale)
}

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        fallbackLng: 'root',
        react: {
            useSuspense: false
        }
    })
    .then(() => {
        changeLanguageWithCulture()
    })

addTranslations(dictionnary)
addLibCoreUiTranslations()
addL10nTranslations()

export default i18n
