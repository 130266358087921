import React from 'react'

import { useTranslation } from 'react-i18next'

import { Search } from '@nickel/core-ui/components/Icons'
import { IconSize } from '@nickel/core-ui/components/Icons/types'
import { References } from '@nickel/foundations/tokens/color'

import { EmptyPredictionContainer } from './styles'

export const EmptyPrediction = () => {
    const { t } = useTranslation('home')

    return (
        <EmptyPredictionContainer>
            <Search size={IconSize.LARGE} color={References.REF_NEUTRAL_N300} />
            {t('emptyResult')}
        </EmptyPredictionContainer>
    )
}
