import styled from 'styled-components'

import Button from '@nickel/core-ui/components/Button'
import { Breakpoint, Spacing } from '@nickel/foundations/tokens/layout'

export const CardContent = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: ${Spacing.MEDIUM};

    @media (min-width: ${Breakpoint.MEDIUM}) {
        border-radius: ${Spacing.LARGE};
    }
`

export const ContentInfos = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${Spacing.LARGE};
`

export const ContentText = styled.div`
    display: flex;
    flex-direction: column;
`

export const DisplayDay = styled.span`
    gap: 1rem;
    text-transform: capitalize;
`
export const DisplayHours = styled.span`
    display: flex;
    flex-direction: column;
`

export const OpeningDayContent = styled.div<{ isCurrentDay: boolean }>`
    display: flex;
    flex-wrap: wrap;
    font-weight: ${(props) => (props.isCurrentDay ? 'bold' : 'initial')};
    gap: ${Spacing.XXLARGE};
    justify-content: space-between;
`

export const ShortOpeningDay = styled.div`
    text-align: center;
    width: 100%;
`

export const WrapperItinerary = styled.div`
    @media (min-width: ${Breakpoint.MEDIUM}) {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: ${Spacing.LARGE};
        padding: 0 ${Spacing.LARGE} ${Spacing.XLARGE} ${Spacing.LARGE};
    }
`

export const ImgContent = styled.img`
    border-radius: ${Spacing.LARGE};
    height: auto;
    width: 100%;
`

export const FullButton = styled(Button)`
    align-items: center;
    justify-content: center;
    margin-top: ${Spacing.LARGE};
    text-align: center;
    width: 100%;

    @media (min-width: ${Breakpoint.MEDIUM}) {
        display: none;
    }
`
