import React from 'react'

import { enableActivityTracking, newTracker } from '@snowplow/browser-tracker'
import { render } from 'react-dom'
import { StyleSheetManager } from 'styled-components'

import { App } from './App'
import { snowplow } from './config/snowplow'
import { AppProvider } from './context'

if (window.REACT_APP_ENABLE_SNOWPLOW_TRACKER === 'true') {
    newTracker('sp', window.REACT_APP_SNOWPLOW_TRACKER_URL, snowplow)
    enableActivityTracking({ minimumVisitLength: 30, heartbeatDelay: 30 })
}

export function initReactWidget() {
    const host = document.getElementById('widget-store-locator') as HTMLDivElement
    const shadow = host.attachShadow({ mode: 'open' })
    const styleSlot = document.createElement('div')
    const renderIn = document.createElement('div')

    shadow.appendChild(styleSlot)
    styleSlot.appendChild(renderIn)

    render(
        <StyleSheetManager target={styleSlot}>
            <React.StrictMode>
                <AppProvider>
                    <App />
                </AppProvider>
            </React.StrictMode>
        </StyleSheetManager>,
        renderIn
    )
}
