import styled from 'styled-components'

import { Spacing } from '@nickel/foundations/tokens/layout'

export const ToasterSlot = styled.div`
    bottom: 0;
    position: fixed;
    right: ${Spacing.XLARGE};
    width: calc(100% - ${Spacing.XLARGE} - ${Spacing.XLARGE});
    z-index: 2;

    div {
        box-sizing: border-box;
    }
`

export const Wrapper = styled.div`
    bottom: 0;
    left: 0;
    width: 100%;
`
