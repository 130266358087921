import { Configuration, StoreLocatorV3ApiFp } from './annuaire-buraliste-api-v3'
import { AddressResourceApiFp } from './location-api'

const configurationStoreLocatorV3 = new Configuration({
    basePath: window.REACT_APP_ANNUAIRE_BURALISTE_API_V3_ENDPOINT
})

const configurationLocationApi = new Configuration({
    basePath: window.REACT_APP_LOCATION_API
})

export const storeLocatorV3Api = StoreLocatorV3ApiFp(configurationStoreLocatorV3)
export const locationApi = AddressResourceApiFp(configurationLocationApi)
