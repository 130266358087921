import { useQuery } from '@tanstack/react-query'

import { storeLocatorV3Api } from 'src/services'
import { Country } from 'src/services/annuaire-buraliste-api-v3'

export const useGetStoresCount = (country: string | undefined) => {
    const query = async () => {
        const makeRequest = await storeLocatorV3Api.getStoresCount(country as Country)
        return makeRequest()
    }

    const { data, error, isFetching } = useQuery(['getStoresCount', { country }], query)

    return {
        error,
        isFetching,
        storeCount: data?.data?.count
    }
}
