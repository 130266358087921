import { TFunction } from 'i18next'

export const getDistance = (distance: number | undefined, t: TFunction<string>) => {
    if (distance) {
        if (distance < 1) {
            return t('distanceToStoreInMeter', { distanceInM: Math.round(distance * 1000) })
        }
        const distanceInKm = Math.floor(distance)
        const decimalPart = parseFloat((distance - Math.floor(distance)).toFixed(3).split('.')[1])

        if (decimalPart === 0) {
            return t('distanceToStoreInKilometerWithoutMeter', {
                distanceInKm
            })
        }
        return t('distanceToStoreInKilometer', {
            distanceInKm,
            distanceInM: decimalPart
        })
    }
}
