import { PositionContext } from 'src/context/types'
import { coordinatesQueryParams } from 'src/features/MapWrapper/domain/domain'

export const isMobileFullScreen = (
    isDesktop: boolean,
    searchPosition: PositionContext | undefined,
    searchParams?: URLSearchParams
) => {
    return !isDesktop && (searchPosition !== undefined || coordinatesQueryParams(searchParams) !== undefined)
}

export const isMobileHomePage = (isDesktop: boolean, searchPosition: PositionContext | undefined) => {
    return !isDesktop && searchPosition === undefined
}

export const disableScroll = () => {
    const body = document.getElementsByTagName('body')[0]
    body.style.overflow = 'hidden'
}

export const enableScroll = () => {
    const body = document.getElementsByTagName('body')[0]
    body.style.overflow = 'auto'
}
