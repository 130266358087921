import React, { useContext, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import Breadcrumb from '@nickel/core-ui/components/Breadcrumb'
import { BreadcrumbItem } from '@nickel/core-ui/components/Breadcrumb/Breadcrumb'
import Spinner from '@nickel/core-ui/components/Spinner'
import Text from '@nickel/core-ui/components/Text'

import Infos from './components/Infos'
import Itinerary from './components/Itinerary'
import WorkingHours from './components/WorkingHours'
import { useGetStoreDetails } from './hooks/useGetStoreDetails'
import { BreadcrumbContainer, Content, DisplayContent, Title, Wrapper } from './styles'

import { AppContext } from 'src/context'
import { useLangCountry } from 'src/hooks/useLangCountry'
import { HOME_ROUTES } from 'src/routes/constants'

export const Details = () => {
    const { t } = useTranslation('home')
    const { storeId } = useParams()
    const { key } = useLangCountry()
    const navigate = useNavigate()
    const { error, isFetching, store } = useGetStoreDetails(storeId)
    const { state } = useContext(AppContext)

    const url = useMemo(() => (state.searchResult.url ? `?${state.searchResult.url}` : ''), [state.searchResult.url])

    const items = useMemo(
        () =>
            [
                {
                    label: t('labelSearchBar'),
                    path: `${HOME_ROUTES[key]}${url}`
                },
                {
                    label: store?.name,
                    path: ''
                }
            ] as BreadcrumbItem[],
        [key, store?.name, t, url]
    )

    if (isFetching)
        return (
            <div data-testid="detail#spinner">
                <Spinner />
            </div>
        )
    if (error) return <Text>{t('errors:default')}</Text>

    return (
        <Wrapper data-testid="details">
            <BreadcrumbContainer>
                <Breadcrumb items={items} onClick={navigate} />
            </BreadcrumbContainer>
            <Title>{store.name}</Title>
            <Content>
                <DisplayContent>
                    <Infos store={store} />
                    <WorkingHours store={store} />
                </DisplayContent>
                <Itinerary store={store} />
            </Content>
        </Wrapper>
    )
}
