import React, { MouseEvent } from 'react'

import { useTranslation } from 'react-i18next'

import { Option } from '@nickel/core-ui/components/SearchInput/types'
import Text from '@nickel/core-ui/components/Text'
import { TextStyle } from '@nickel/core-ui/components/Text/types'

import { EmptyPrediction } from './EmptyPrediction'
import { ResultPrediction } from './ResultPrediction'
import { MobileSuggestionContainer } from './styles'

export const MobileSuggestion = ({
    isActive,
    onSelect,
    predictions
}: {
    isActive: boolean
    onSelect: (id: string) => (e: MouseEvent) => void
    predictions: Option[]
}) => {
    const { t } = useTranslation('home')

    return (
        <MobileSuggestionContainer isActive={isActive}>
            <Text textStyle={TextStyle.BOLD}>{t('searchResult')}</Text>
            {predictions.length > 0 ? (
                <ResultPrediction onSelect={onSelect} predictions={predictions} />
            ) : (
                <EmptyPrediction />
            )}
        </MobileSuggestionContainer>
    )
}
