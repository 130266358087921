import React, { useEffect } from 'react'

import { TextStyle } from '@nickel/core-ui/components/Text/types'

import { DrawerAnimation, DrawerBackground, DrawerHeader, DrawerTitle } from './style'

import { CrossButton } from 'src/styles'
import { disableScroll } from 'src/utils/layout/layout'

export const Drawer = ({
    isActive,
    onClose,
    title
}: {
    isActive: boolean
    onClose: (e: React.MouseEvent) => void
    title: string
}) => {
    useEffect(() => {
        if (isActive) {
            disableScroll()
        }
    }, [isActive])

    return (
        <div data-testid="drawer">
            <DrawerBackground isActive={isActive} />
            <DrawerHeader isActive={isActive}>
                <DrawerTitle textStyle={TextStyle.BOLD}>{title}</DrawerTitle>
                <CrossButton onClick={onClose} data-testid="closeDrawer" />
            </DrawerHeader>
            <DrawerAnimation isActive={isActive} />
        </div>
    )
}
