import { trackStructEvent } from '@snowplow/browser-tracker'

export enum SnowplowCategoryEvent {
    USER_CLICK = 'click'
}

export enum SnowplowAction {
    GEOLOCATION = 'store_locator_button_geolocalisation',
    FILTER = 'store_locator_button_open_filter',
    STATUS = 'store_locator_button_currently_open',
    MAPS = 'store_locator_button_open_google_maps',
    SEARCH = 'store_locator_search_done',
    MAPS_POINTER = 'store_locator_point_of_sale_pointer',
    CLOSE_SEARCH_PANEL = 'store_locator_left_panel_cross',
    STORE_CLICK_SEARCH_PANEL = 'store_locator_left_panel_popup_pos',
    STORE_MORE_INFO_SEARCH_PANEL = 'store_locator_left_panel_more_info',
    STORE_ITINERARY_SEARCH_PANEL = 'store_locator_left_panel_itinerary',
    UNPIN_POS = 'store_locator_unpin_popup_pos',
    STORE_MORE_INFO_PINED = 'store_locator_more_info_button_panel_map',
    STORE_ITINERARY_PINED = 'store_locator_itinerary_button_panel_map'
}

export const trackClickEvent = (action: SnowplowAction, property?: string) => {
    if (window.REACT_APP_ENABLE_SNOWPLOW_TRACKER === 'true')
        trackStructEvent({
            category: SnowplowCategoryEvent.USER_CLICK,
            action,
            property
        })
}
