import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { MarkerClusterer, SuperClusterViewportAlgorithm } from '@googlemaps/markerclusterer'
import { useSearchParams } from 'react-router-dom'

import { coordinatesQueryParams, getDefaultCenter, getDefaultZoom, getIcon } from '../../domain/domain'
import usePosCoordinates from '../../hooks/usePosCoordinates'
import { GoogleMap } from '../../styles'
import { MarkerParam, RenderProps } from '../../types'
import { CLUSTER_LABEL_KEY, CLUSTERS_RADIUS, defaultClusterSvg, MIN_ZOOM } from '../utils'

import { moveToPin, updateGeolocationPin, updatePin, updatePosition } from './map.domain'

import { AppContext } from 'src/context'
import { AppActionsType } from 'src/context/types'
import { useLangCountry } from 'src/hooks/useLangCountry'
import { useMediaQuery } from 'src/hooks/useMediaQuery'
import { SnowplowAction, trackClickEvent } from 'src/utils/Tracking/utils'

export const Map = ({
    setBoundingBox,
    setZoom,
    zoom
}: {
    setBoundingBox: React.Dispatch<google.maps.LatLngBounds | undefined>
    setZoom: React.Dispatch<number>
    zoom: number
}) => {
    const { state, dispatch } = useContext(AppContext)

    const [markers, setMarkers] = useState<google.maps.marker.AdvancedMarkerElement[]>([])
    const [markerClicked, setMarkerClicked] = useState<MarkerParam | undefined>(undefined)
    const [idleListener, setIdleListener] = useState<google.maps.MapsEventListener | undefined>(undefined)
    const [zoomListener, setZoomListener] = useState<google.maps.MapsEventListener | undefined>(undefined)
    const [clusterer, setClusterer] = useState<MarkerClusterer | undefined>(undefined)
    const [map, setMap] = useState<google.maps.Map>()
    const [currentPositionMarker, setCurrentPositionMarker] = useState<
        google.maps.marker.AdvancedMarkerElement | undefined
    >(undefined)
    const [searchParams, setSearchParams] = useSearchParams()

    const { country } = useLangCountry()
    const { stores } = usePosCoordinates(state?.filters)
    const isDesktop = useMediaQuery()

    const ref = useRef<HTMLDivElement>(null)

    const defaultCenter = useMemo(() => getDefaultCenter(country), [country])

    const setStorePinnedId = (id: string | undefined) => {
        dispatch({
            type: AppActionsType.SET_STORE_PINNED_ID,
            payload: id
        })
    }

    useEffect(() => {
        if (map) {
            const coordinates = coordinatesQueryParams(searchParams)
            if (coordinates !== undefined) {
                map.setZoom(coordinates.z)
                map.setCenter(new google.maps.LatLng(coordinates.x, coordinates.y))
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map])

    const moveMapToPin = useCallback(
        (point: google.maps.LatLng) => {
            moveToPin(map, point)
        },
        [map]
    )

    useEffect(() => {
        updatePin({ markerClicked, markers, setMarkerClicked, storePinnedId: state.storePinnedId })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.storePinnedId])

    const addGeolocation = useCallback(
        (point: google.maps.LatLng) => {
            updateGeolocationPin({ currentPositionMarker, map, point, setCurrentPositionMarker })
        },
        [currentPositionMarker, map]
    )

    // When search position change
    useEffect(() => {
        updatePosition({ addGeolocation, map, moveMapToPin, searchPosition: state.mapPosition })
    }, [addGeolocation, map, moveMapToPin, setCurrentPositionMarker, state.mapPosition, currentPositionMarker])

    const createPoints = useCallback(() => {
        if (map) {
            const parser = new DOMParser()
            const tempMarkers: google.maps.marker.AdvancedMarkerElement[] = []
            stores.forEach((store) => {
                const { latitude, longitude } = store.coordinates
                const point = new google.maps.LatLng(Number(latitude), Number(longitude))
                const marker = new google.maps.marker.AdvancedMarkerElement({
                    position: point,
                    content: parser.parseFromString(getIcon(store.state), 'image/svg+xml').documentElement
                })
                marker.id = store.id

                marker.addListener('click', () => {
                    trackClickEvent(SnowplowAction.MAPS_POINTER)
                    setStorePinnedId(store.id)
                    map.panTo(point)
                })

                tempMarkers.push(marker)
            })

            const renderer = {
                render({ count, position }: RenderProps) {
                    const svgElement = parser.parseFromString(
                        defaultClusterSvg.replace(CLUSTER_LABEL_KEY, String(count)),
                        'image/svg+xml'
                    ).documentElement
                    return new google.maps.marker.AdvancedMarkerElement({
                        content: svgElement,
                        position,
                        zIndex: Number(10000) + count
                    })
                }
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const algorithm = new SuperClusterViewportAlgorithm({ radius: CLUSTERS_RADIUS })

            if (clusterer === undefined) {
                setClusterer(new MarkerClusterer({ map, markers: tempMarkers, algorithm, renderer }))
            } else {
                clusterer.clearMarkers()
                clusterer.addMarkers(tempMarkers)
            }
            setMarkers(tempMarkers)

            updatePin({ markerClicked, markers: tempMarkers, setMarkerClicked, storePinnedId: state.storePinnedId })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, stores, setStorePinnedId])

    const onIdle = useCallback(
        (m: google.maps.Map) => {
            const center = m.getCenter()
            const mapZoom = m.getZoom()
            if (center !== undefined && mapZoom !== undefined) {
                const paramsObj = {
                    x: center.lat().toString(),
                    y: center.lng().toString(),
                    z: mapZoom.toString()
                }
                setSearchParams(paramsObj)
            }

            setBoundingBox(m.getBounds())
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setBoundingBox]
    )

    const onZoom = useCallback(
        (m: google.maps.Map) => {
            setZoom(m.getZoom() || getDefaultZoom(country))
            m.setOptions({})
        },
        [setZoom, country]
    )
    // When map is ready
    useEffect(() => {
        if (map && markers.length === 0 && stores.length > 0) {
            createPoints()
            setIdleListener(map.addListener('idle', () => onIdle(map)))
            setZoomListener(map.addListener('zoom_changed', () => onZoom(map)))
        }
    }, [createPoints, clusterer, idleListener, markers, onIdle, onZoom, map, stores, zoomListener])

    // when stores reload
    useEffect(() => {
        if (map && clusterer !== undefined) {
            setStorePinnedId(undefined)
            createPoints()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, stores])

    // Create Map
    useEffect(() => {
        if (ref.current && !map) {
            setMap(
                new window.google.maps.Map(ref.current, {
                    center: defaultCenter,
                    fullscreenControl: false,
                    gestureHandling: isDesktop ? 'cooperative' : 'greedy',
                    mapTypeControl: false,
                    mapTypeControlOptions: {
                        mapTypeIds: [window.google.maps.MapTypeId.ROADMAP]
                    },
                    minZoom: MIN_ZOOM,
                    streetViewControl: false,
                    zoomControl: isDesktop,
                    mapId: 'storeLocatorMap',
                    zoom
                })
            )
        }
    }, [defaultCenter, isDesktop, map, ref, zoom])

    return <GoogleMap data-testid="map" ref={ref} />
}
