import { Localisation } from '../../types'
import { currentPositionSvg, defaultMarkerClickedSvg, ZOOM_TO_PAN } from '../utils'

import { GeolocationPin, UpdatePin, UpdatePositionPin } from './types'

export const moveToPin = (map: google.maps.Map | undefined, point: google.maps.LatLng) => {
    if (map) {
        map.panTo(point)
        const currentZoom = map.getZoom()
        if (currentZoom && currentZoom < ZOOM_TO_PAN) {
            setTimeout(() => {
                map.setZoom(ZOOM_TO_PAN)
            }, 500)
        }
    }
}

export const updateGeolocationPin = ({
    currentPositionMarker,
    map,
    point,
    setCurrentPositionMarker
}: GeolocationPin) => {
    if (map) {
        let marker
        if (!currentPositionMarker) {
            marker = new google.maps.marker.AdvancedMarkerElement()
            marker.map = map
        } else {
            marker = currentPositionMarker
        }

        marker.position = point

        marker.content = new DOMParser().parseFromString(currentPositionSvg, 'image/svg+xml').documentElement

        setCurrentPositionMarker(marker)
    }
}

export const searchIdInMarkers = (id: string | undefined, markers: google.maps.marker.AdvancedMarkerElement[]) => {
    return markers.find((marker) => marker.id === id)
}

export const updatePin = ({ markerClicked, markers, setMarkerClicked, storePinnedId }: UpdatePin) => {
    const markerToClick = searchIdInMarkers(storePinnedId, markers)

    if (markerToClick) {
        setMarkerClicked({
            id: markerToClick.id,
            content: markerToClick.content
        })

        markerToClick.content = new DOMParser().parseFromString(
            defaultMarkerClickedSvg,
            'image/svg+xml'
        ).documentElement
    } else {
        setMarkerClicked(undefined)
    }

    if (markerClicked) {
        const oldRealMarker = searchIdInMarkers(markerClicked.id, markers)

        if (oldRealMarker) {
            oldRealMarker.content = markerClicked.content
        }
    }
}

export const updatePosition = ({ addGeolocation, map, moveMapToPin, searchPosition }: UpdatePositionPin) => {
    if (searchPosition && map) {
        if (searchPosition.point !== undefined) {
            const point = new google.maps.LatLng(searchPosition.point.lat, searchPosition.point.lng)
            if (searchPosition.withGeolocation) {
                addGeolocation(point)
            }
            moveMapToPin(point)
        }

        if (searchPosition.viewport) {
            map.fitBounds(searchPosition.viewport, 0)
        }
    }
}

const compare = (boundingBoxLng: number, boundingBoxLat: number, pointLng: number, pointLat: number) => {
    return pointLng.toFixed(4) !== boundingBoxLng.toFixed(4) || pointLat.toFixed(4) !== boundingBoxLat.toFixed(4)
}

export const pointHasMoved = (boundingBox: google.maps.LatLngBounds | undefined, point: Localisation | undefined) => {
    if (!boundingBox || !point) {
        return false
    }

    return compare(boundingBox.getCenter().lng(), boundingBox.getCenter().lat(), point.lng, point.lat)
}

export const viewportHasMoved = (
    boundingBox: google.maps.LatLngBounds | undefined,
    viewport: google.maps.LatLngBounds | undefined
) => {
    if (!boundingBox || !viewport) {
        return false
    }

    return compare(
        boundingBox.getCenter().lng(),
        boundingBox.getCenter().lat(),
        viewport.getCenter().lng(),
        viewport.getCenter().lat()
    )
}
