import { useQuery } from '@tanstack/react-query'

import { storeLocatorV3Api } from 'src/services'
import { Store } from 'src/services/annuaire-buraliste-api-v3'

export const useGetStoreDetails = (storeId: string | undefined) => {
    const query = async () => {
        if (!storeId) return
        const makeRequest = await storeLocatorV3Api.getStoreById(storeId)
        return makeRequest()
    }

    const { data, error, isFetching } = useQuery(['getStoreDetails', { storeId }], query)
    return {
        error,
        isFetching,
        store: data?.data ?? ({} as Store)
    }
}
