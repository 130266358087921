import React, { useContext } from 'react'

import { useTranslation } from 'react-i18next'

import Button from '@nickel/core-ui/components/Button'

import { MobileFiltersContainer, StickyButtons } from '../styles'

import { Filters } from './Filters'

import { Drawer } from 'src/components/Drawer/Drawer'
import { AppContext } from 'src/context'
import { AppActionsType } from 'src/context/types'

export const MobileFilters = ({
    count,
    isActive,
    resetDrawer
}: {
    count: number
    isActive: boolean
    resetDrawer: () => void
}) => {
    const { t } = useTranslation('home')

    const { dispatch } = useContext(AppContext)

    const _resetFilters = () => {
        dispatch({
            type: AppActionsType.UPDATE_FILTERS
        })
    }

    return (
        <MobileFiltersContainer isActive={isActive} data-testid="mobileFilters">
            <Drawer isActive={isActive} onClose={resetDrawer} title={t('filters')} />

            <Filters />

            <StickyButtons>
                <Button
                    className="alignSelf"
                    onClick={_resetFilters}
                    testId="clearAll"
                    text={t('clearAll')}
                    variant="ghost"
                />
                <Button
                    className="alignSelf"
                    onClick={resetDrawer}
                    testId="filter"
                    text={t('filterAction') + (count > 0 ? ` (${count})` : '')}
                    variant="primary"
                />
            </StickyButtons>
        </MobileFiltersContainer>
    )
}
