import React, { createContext, Dispatch, ReactNode, useMemo, useReducer } from 'react'

import { AppActions } from './actions'
import { initialState, mainReducer } from './reducers'
import { AllContext } from './types'

const AppContext = createContext<{
    state: AllContext
    dispatch: Dispatch<AppActions>
}>({
    state: initialState,
    dispatch: () => null
})

const AppProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(mainReducer, initialState)
    const stateProps = useMemo(() => ({ state, dispatch }), [state])

    return <AppContext.Provider value={stateProps}>{children}</AppContext.Provider>
}

export { AppProvider, AppContext }
