import { useQuery } from '@tanstack/react-query'

import { UseAddressDetails } from './types'

import { locationApi } from 'src/services'

const useAddressDetails: UseAddressDetails = (sessionToken: string, predictionId: string | undefined) => {
    const query = async () => {
        if (!predictionId) return
        const makeRequest = await locationApi.getAddressDetails(sessionToken, predictionId)
        return makeRequest()
    }
    const { data, error, isFetching } = useQuery(['addressDetails', { predictionId }], query, {
        enabled: !!predictionId
    })

    return {
        addressDetail: data?.data,
        error,
        isFetching
    }
}

export default useAddressDetails
