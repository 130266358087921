import { FiltersType } from '../../../types'

export const getFilterCount = (filters: FiltersType | undefined) => {
    if (filters !== undefined) {
        return Object.keys(filters).reduce((acc: number, curr: string) => {
            if (filters[curr as keyof FiltersType]) {
                return acc + 1
            }
            return acc
        }, 0)
    }
    return 0
}
