import React, { useContext } from 'react'

import { QueryClientProvider } from '@tanstack/react-query'
import { APIProvider } from '@vis.gl/react-google-maps'
import { I18nextProvider } from 'react-i18next'
import { RouterProvider } from 'react-router-dom'

import CoreUiGlobalStyle from '@nickel/foundations/components/config/GlobalStyle'
import { SessionTokenProvider } from '@nickel/location/sessionTokenContext'

import { queryConfig } from './config/query'
import { AppContext } from './context'
import i18n from './i18n'
import { router } from './routes/Router'
import { GlobalCustomStyle, GlobalStyle } from './styles'
import { getLangFromPath } from './utils/i18n'

declare global {
    interface Window {
        REACT_APP_API_ENDPOINT: string
        REACT_APP_ANNUAIRE_BURALISTE_API_V3_ENDPOINT: string
        REACT_APP_LOCATION_API: string
        REACT_APP_GOOGLE_MAPS_KEY: string
        REACT_APP_MSW: string
        REACT_APP_SNOWPLOW_TRACKER_URL: string
        REACT_APP_SNOWPLOW_TRACKER_APP_ID: string
        REACT_APP_ENABLE_SNOWPLOW_TRACKER: string
    }
}

export const App = () => {
    const { state } = useContext(AppContext)

    const lang = getLangFromPath(window.location.pathname)

    return (
        <I18nextProvider i18n={i18n}>
            <GlobalCustomStyle data-testid="app" hasOverlay={state.overlay.hasOverlay}>
                <QueryClientProvider client={queryConfig}>
                    <CoreUiGlobalStyle />
                    <GlobalStyle hasOverlay={state.overlay.hasOverlay} />
                    <SessionTokenProvider>
                        <APIProvider
                            apiKey={window.REACT_APP_GOOGLE_MAPS_KEY}
                            language={lang}
                            libraries={['marker', 'maps', 'core']}
                        >
                            <RouterProvider router={router} />
                        </APIProvider>
                    </SessionTokenProvider>
                </QueryClientProvider>
            </GlobalCustomStyle>
        </I18nextProvider>
    )
}
