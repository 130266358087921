import { useQuery } from '@tanstack/react-query'

import { storeLocatorV3Api } from 'src/services'

export const useGetStore = (storeId: string | undefined) => {
    const query = async () => {
        if (!storeId) return
        const makeRequest = await storeLocatorV3Api.getLightStoreById(storeId)
        return makeRequest()
    }

    const { data, error, isFetching } = useQuery(['getStore', { storeId }], query, {
        enabled: !!storeId
    })

    return {
        error,
        isFetching,
        storePinned: data?.data || undefined
    }
}
