import React from 'react'

import { useTranslation } from 'react-i18next'

import Button from '@nickel/core-ui/components/Button'
import { MapMarker } from '@nickel/core-ui/components/Icons'
import { Breakpoint } from '@nickel/foundations/tokens/layout'

import { MapItinerarySubTitle } from '../styles'

import { FullButton, ImgContent, WrapperItinerary } from './styles'

import { useLangCountry } from 'src/hooks/useLangCountry'
import { useMediaQuery } from 'src/hooks/useMediaQuery'
import { Store } from 'src/services/annuaire-buraliste-api-v3'
import { getItineraryLink, openItinerary } from 'src/utils/links'
import { SnowplowAction, trackClickEvent } from 'src/utils/Tracking/utils'

const Itinerary = ({ store }: { store: Store }) => {
    const { t } = useTranslation('infos')
    const { lang } = useLangCountry()
    const isTabletOrDesktop = useMediaQuery(`(min-width: ${Breakpoint.MEDIUM})`)

    const googleMapStaticUrl = `https://maps.googleapis.com/maps/api/staticmap
    ?center=${store.coordinates?.latitude},${store.coordinates?.longitude}
    &zoom=16
    &scale=2
    &size=920x300
    &maptype=terrain
    &language=${lang}
    &markers=icon:https://widget-store-locator.nickel.eu/defaultMarker.png|${store.coordinates?.latitude},${store.coordinates?.longitude}
    &key=${window.REACT_APP_GOOGLE_MAPS_KEY}`.replace(/\s/gm, '')

    return (
        <WrapperItinerary>
            <MapItinerarySubTitle>
                {t('localisation')}
                <a
                    data-testid="itineraryGoogleLink"
                    href={getItineraryLink(store.coordinates)}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Button
                        Icon={MapMarker}
                        onClick={() => trackClickEvent(SnowplowAction.MAPS)}
                        testId="itineraryGoogleButton"
                        text={t('openInGoogleMap')}
                        variant="ghost"
                    />
                </a>
            </MapItinerarySubTitle>
            {isTabletOrDesktop && (
                <a href={getItineraryLink(store.coordinates)} data-testid="staticMap" target="_blank" rel="noreferrer">
                    <ImgContent src={googleMapStaticUrl} alt={t('itinerary')} />
                </a>
            )}
            <FullButton
                Icon={MapMarker}
                onClick={() => openItinerary(store.coordinates)}
                testId="redirectToGoogleMap"
                text={t('home:posCard.itinerary')}
            />
        </WrapperItinerary>
    )
}

export default Itinerary
