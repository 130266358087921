import styled from 'styled-components'

import { Spacing } from '@nickel/foundations/tokens/layout'

export const Wrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: ${Spacing.XLARGE};
    justify-content: center;
    padding-top: ${Spacing.XLARGE};
    text-align: center;
`
