import styled, { css } from 'styled-components'

import Card from '@nickel/core-ui/components/Card'
import { Unpin } from '@nickel/core-ui/components/Icons'
import { References } from '@nickel/foundations/tokens/color'
import { Breakpoint, Spacing } from '@nickel/foundations/tokens/layout'
import { FontSize } from '@nickel/foundations/tokens/typography'

export const Wrapper = styled(Card)<{ selected?: boolean; isMobileFullScreen?: boolean }>`
    background-color: ${References.REF_NEUTRAL_N0};
    border-radius: 16px;
    box-shadow: 0 4px 16px 0 rgba(25, 31, 41, 0.1);
    min-width: 300px;
    overflow: unset;
    width: ${({ selected }) => (selected ? 'min-content' : 'unset')};

    &:hover {
        cursor: ${({ selected }) => (selected ? 'inherit' : 'pointer')};

        .posCardHeader,
        .posCardContent,
        .posCardFooter {
            background-color: ${({ selected }) => (selected ? References.REF_NEUTRAL_N0 : References.REF_NEUTRAL_N10)};
        }
    }

    ${({ isMobileFullScreen }) => {
        if (isMobileFullScreen)
            return css`
                bottom: ${Spacing.LARGE};
                left: 50%;
                margin: auto;
                position: fixed;
                transform: translate(-50%, 0);
                width: 90vw;
                z-index: 1;
            `
    }}
    .posCardHeader {
        align-items: flex-start;
        border-radius: 16px 16px 0 0;
        display: flex;
        flex-direction: column;
        gap: ${Spacing.SMALL};
        padding: ${Spacing.LARGE};
        position: unset;
        ${({ selected, isMobileFullScreen }) =>
            selected && !isMobileFullScreen && `background-color: ${References.REF_NEUTRAL_N0};`}
    }

    .posCardContent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 ${Spacing.LARGE};

        ${({ selected, isMobileFullScreen }) =>
            selected && !isMobileFullScreen && `background-color: ${References.REF_NEUTRAL_N0};`}
        div:first-of-type {
            display: flex;
            flex-direction: column;
        }
    }

    .posCardFooter {
        border-radius: 0 0 16px 16px;
        gap: ${Spacing.LARGE};
        justify-content: unset;
        margin-top: unset;
        padding: ${Spacing.LARGE};
        ${({ selected, isMobileFullScreen }) =>
            selected && !isMobileFullScreen && `background-color: ${References.REF_NEUTRAL_N0};`}
    }

    @media (min-width: ${Breakpoint.MEDIUM}) {
        .fixWidthButton {
            min-width: fit-content;
        }
    }
`

export const Title = styled.span`
    align-items: center;
    display: flex;
    font-size: ${FontSize.LARGE};
    font-weight: bold;
    justify-content: space-between;
    width: 100%;
`
export const UnpinButton = styled(Unpin)`
    flex-shrink: 0;

    :hover {
        cursor: pointer;
    }
`

export const LinkButton = styled.a`
    max-width: calc(50% - ${Spacing.MEDIUM});
    text-overflow: ellipsis;
`
