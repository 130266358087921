import React, { useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'

import Button from '@nickel/core-ui/components/Button'
import Text from '@nickel/core-ui/components/Text'

import { SubTitle } from '../styles'

import { CardContent, ContentInfos, DisplayDay, DisplayHours, OpeningDayContent, ShortOpeningDay } from './styles'

import { useLangCountry } from 'src/hooks/useLangCountry'
import { Store, OpeningDay, OpeningHour } from 'src/services/annuaire-buraliste-api-v3'
import { formatDisplayedHours, getCurrentDayIndex, getDayOfWeekLabelTranslated, sortDay } from 'src/utils/Date'

const WorkingHours = ({ store }: { store: Store }) => {
    const { t } = useTranslation('infos')
    const { lang, country } = useLangCountry()
    const currentDayIndex = useMemo(() => getCurrentDayIndex(store.address?.timezone), [store])

    const [displayAllDays, setDisplayAllDays] = useState(false)

    const _renderDay = (openingDay: OpeningDay) => {
        const { day, openingHours } = openingDay
        return openingHours && openingHours.length > 0 ? (
            openingHours.map((openingTime: OpeningHour, index: number) => {
                return (
                    <div key={`${day}_${String(index)}`}>
                        {`${formatDisplayedHours(openingTime.start, lang, country)} - ${formatDisplayedHours(
                            openingTime.end,
                            lang,
                            country
                        )}`}
                    </div>
                )
            })
        ) : (
            <Text>{t('closed')}</Text>
        )
    }
    return (
        <CardContent>
            <SubTitle>{t('workingHours')}</SubTitle>
            <ContentInfos>
                {store.openingDays &&
                    sortDay(store.openingDays)?.map((openingDay, index) => {
                        if (displayAllDays) {
                            return (
                                <OpeningDayContent key={openingDay.day} isCurrentDay={currentDayIndex === index}>
                                    <DisplayDay>{`${getDayOfWeekLabelTranslated(openingDay.day, lang)} :`}</DisplayDay>
                                    <DisplayHours>{_renderDay(openingDay)}</DisplayHours>
                                </OpeningDayContent>
                            )
                        }

                        return index === currentDayIndex ? (
                            <OpeningDayContent key={openingDay.day} isCurrentDay>
                                <DisplayDay>{t('today')}</DisplayDay>
                                <DisplayHours>{_renderDay(openingDay)}</DisplayHours>
                            </OpeningDayContent>
                        ) : null
                    })}
            </ContentInfos>
            {!displayAllDays && (
                <ShortOpeningDay>
                    <Button
                        onClick={() => setDisplayAllDays(true)}
                        testId="displayAllDays"
                        text={t('displayAllTimes')}
                        variant="ghost"
                    />
                </ShortOpeningDay>
            )}
        </CardContent>
    )
}

export default WorkingHours
