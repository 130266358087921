import styled from 'styled-components'

import { Spacing } from '@nickel/foundations/tokens/layout'
import { FontSize, FontWeight } from '@nickel/foundations/tokens/typography'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    gap: ${Spacing.LARGE};
`

export const PosList = styled.div`
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    gap: ${Spacing.XXLARGE};
    overflow-y: auto;
    padding: calc(${Spacing.LARGE} - ${Spacing.SMALL});
`

export const Title = styled.span`
    align-items: center;
    display: flex;
    font-size: ${FontSize.XXXLARGE};
    font-weight: ${FontWeight.BOLD};
    justify-content: space-between;
    width: 100%;
`
export const ResultDetailMessage = styled.span`
    font-weight: ${FontWeight.BOLD};
`
