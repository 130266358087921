import { useContext } from 'react'

import { useQuery } from '@tanstack/react-query'

import { STORES_PAGE_SIZE } from '../domain/domain'

import { AppContext } from 'src/context'
import { storeLocatorV3Api } from 'src/services'

export const useGetStores = (culture: string, isDesktop: boolean) => {
    const { state } = useContext(AppContext)

    const query = async () => {
        const makeRequest = await storeLocatorV3Api.getStores(
            culture,
            state.searchResult.pageSize !== undefined ? 0 : state.pageNumber,
            state.searchPosition?.viewport?.getSouthWest().lat(),
            state.searchPosition?.viewport?.getSouthWest().lng(),
            state.searchPosition?.viewport?.getNorthEast().lat(),
            state.searchPosition?.viewport?.getNorthEast().lng(),
            state.searchPosition?.point?.lat,
            state.searchPosition?.point?.lng,
            state.searchResult.pageSize ?? STORES_PAGE_SIZE,
            state.filters?.onlyOpened
        )
        return makeRequest()
    }

    const { data, isFetching, error } = useQuery(
        [
            'getStores',
            {
                culture,
                isDesktop,
                pageNumber: state.pageNumber,
                searchPosition: state.searchPosition,
                onlyOpened: state.filters?.onlyOpened
            }
        ],
        query,
        {
            enabled:
                culture !== '' &&
                isDesktop &&
                state.searchPosition !== undefined &&
                (state.searchPosition.point !== undefined || state.searchPosition?.viewport !== undefined)
        }
    )

    return {
        storesRequest: data?.data,
        isFetching,
        error
    }
}
