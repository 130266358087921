import { useEffect } from 'react'

import { trackPageView } from '@snowplow/browser-tracker'
import { useLocation } from 'react-router-dom'

export const useTrackPageView = () => {
    const location = useLocation()
    useEffect(() => {
        if (window.REACT_APP_ENABLE_SNOWPLOW_TRACKER === 'true') trackPageView({ title: location.pathname })
    }, [location.pathname])
}
