export enum AUTHORIZE_LANG_COUNTRY {
    FR_FR = 'FR_FR',
    EN_FR = 'EN_FR',
    ES_ES = 'ES_ES',
    EN_ES = 'EN_ES',
    FR_BE = 'FR_BE',
    NL_BE = 'NL_BE',
    EN_BE = 'EN_BE',
    PT_PT = 'PT_PT',
    EN_PT = 'EN_PT',
    DE_DE = 'DE_DE',
    EN_DE = 'EN_DE'
}

export const HOME_ROUTES = {
    [AUTHORIZE_LANG_COUNTRY.FR_FR]: '/fr/points-de-vente',
    [AUTHORIZE_LANG_COUNTRY.EN_FR]: '/en-fr/points-of-sale',
    [AUTHORIZE_LANG_COUNTRY.ES_ES]: '/es-es/puntos-de-venta',
    [AUTHORIZE_LANG_COUNTRY.EN_ES]: '/en-es/points-of-sale',
    [AUTHORIZE_LANG_COUNTRY.FR_BE]: '/fr-be/points-de-vente',
    [AUTHORIZE_LANG_COUNTRY.NL_BE]: '/nl-be/verkooppunt',
    [AUTHORIZE_LANG_COUNTRY.EN_BE]: '/en-be/points-of-sale',
    [AUTHORIZE_LANG_COUNTRY.PT_PT]: '/pt-pt/agente-nickel',
    [AUTHORIZE_LANG_COUNTRY.EN_PT]: '/en-pt/points-of-sale',
    [AUTHORIZE_LANG_COUNTRY.DE_DE]: '/de-de/verkaufsstellen',
    [AUTHORIZE_LANG_COUNTRY.EN_DE]: '/en-de/points-of-sale'
}

export const DETAILS_STORES_ROUTES = {
    [AUTHORIZE_LANG_COUNTRY.FR_FR]: `${HOME_ROUTES[AUTHORIZE_LANG_COUNTRY.FR_FR]}/:storeId`,
    [AUTHORIZE_LANG_COUNTRY.EN_FR]: `${HOME_ROUTES[AUTHORIZE_LANG_COUNTRY.EN_FR]}/:storeId`,
    [AUTHORIZE_LANG_COUNTRY.ES_ES]: `${HOME_ROUTES[AUTHORIZE_LANG_COUNTRY.ES_ES]}/:storeId`,
    [AUTHORIZE_LANG_COUNTRY.EN_ES]: `${HOME_ROUTES[AUTHORIZE_LANG_COUNTRY.EN_ES]}/:storeId`,
    [AUTHORIZE_LANG_COUNTRY.FR_BE]: `${HOME_ROUTES[AUTHORIZE_LANG_COUNTRY.FR_BE]}/:storeId`,
    [AUTHORIZE_LANG_COUNTRY.NL_BE]: `${HOME_ROUTES[AUTHORIZE_LANG_COUNTRY.NL_BE]}/:storeId`,
    [AUTHORIZE_LANG_COUNTRY.EN_BE]: `${HOME_ROUTES[AUTHORIZE_LANG_COUNTRY.EN_BE]}/:storeId`,
    [AUTHORIZE_LANG_COUNTRY.PT_PT]: `${HOME_ROUTES[AUTHORIZE_LANG_COUNTRY.PT_PT]}/:storeId`,
    [AUTHORIZE_LANG_COUNTRY.EN_PT]: `${HOME_ROUTES[AUTHORIZE_LANG_COUNTRY.EN_PT]}/:storeId`,
    [AUTHORIZE_LANG_COUNTRY.DE_DE]: `${HOME_ROUTES[AUTHORIZE_LANG_COUNTRY.DE_DE]}/:storeId`,
    [AUTHORIZE_LANG_COUNTRY.EN_DE]: `${HOME_ROUTES[AUTHORIZE_LANG_COUNTRY.EN_DE]}/:storeId`
}

export const DRUPAL_HOST = 'nickel.eu'
