import styled from 'styled-components'

import { Breakpoint, Spacing } from '@nickel/foundations/tokens/layout'
import { FontSize } from '@nickel/foundations/tokens/typography'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${Spacing.LARGE};
    padding: ${Spacing.LARGE};
    position: relative;

    @media (min-width: ${Breakpoint.MEDIUM}) {
        gap: ${Spacing.XLARGE};
    }
`

export const SearchMapWrapper = styled.div`
    display: flex;
    flex: 1 1 0;
    gap: ${Spacing.XLARGE};
    width: 100%;
`

export const PosListWrapper = styled.div`
    display: flex;
    flex: 1 1 40%;
    flex-direction: column;
    gap: ${Spacing.LARGE};
`

export const StoreList = styled.div`
    background: var(--Light-Gradient, linear-gradient(180deg, #cce4ff 0%, #e5f2ff 100%));
    border-radius: 16px;
    padding: ${Spacing.LARGE};

    & span {
        font-size: ${FontSize.LARGE};
        font-weight: bold;
    }
`

export const StoreListDetail = styled.div`
    display: flex;

    span {
        flex: 1 1 0;
    }
`
