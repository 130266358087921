/* tslint:disable */
/* eslint-disable */
/**
 * location-api
 * This service expose endpoints to help with management of emails (validate), IPs (describe) and addresses (resolve, ...).
 *
 * The version of the OpenAPI document: v1.1.3
 * Contact: team-onboarders@nickel.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction
} from './common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface AddressPredictionDetailsView
 */
export interface AddressPredictionDetailsView {
    /**
     *
     * @type {string}
     * @memberof AddressPredictionDetailsView
     */
    formattedAddress?: string
    /**
     *
     * @type {string}
     * @memberof AddressPredictionDetailsView
     */
    streetNumber?: string
    /**
     *
     * @type {string}
     * @memberof AddressPredictionDetailsView
     */
    streetName?: string
    /**
     *
     * @type {string}
     * @memberof AddressPredictionDetailsView
     */
    postalCode?: string
    /**
     *
     * @type {string}
     * @memberof AddressPredictionDetailsView
     */
    locality?: string
    /**
     *
     * @type {string}
     * @memberof AddressPredictionDetailsView
     */
    administrativeArea?: string
    /**
     *
     * @type {string}
     * @memberof AddressPredictionDetailsView
     */
    country?: string
    /**
     *
     * @type {number}
     * @memberof AddressPredictionDetailsView
     */
    latitude?: number
    /**
     *
     * @type {number}
     * @memberof AddressPredictionDetailsView
     */
    longitude?: number
    /**
     *
     * @type {ViewportResponse}
     * @memberof AddressPredictionDetailsView
     */
    viewport?: ViewportResponse
}
/**
 *
 * @export
 * @interface AddressPredictionListItemView
 */
export interface AddressPredictionListItemView {
    /**
     *
     * @type {string}
     * @memberof AddressPredictionListItemView
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof AddressPredictionListItemView
     */
    label?: string
}
/**
 *
 * @export
 * @interface AddressPredictionListView
 */
export interface AddressPredictionListView {
    /**
     *
     * @type {Array<AddressPredictionListItemView>}
     * @memberof AddressPredictionListView
     */
    predictions?: Array<AddressPredictionListItemView>
}
/**
 *
 * @export
 * @interface LocationResponse
 */
export interface LocationResponse {
    /**
     *
     * @type {number}
     * @memberof LocationResponse
     */
    lat: number
    /**
     *
     * @type {number}
     * @memberof LocationResponse
     */
    lng: number
}
/**
 *
 * @export
 * @interface ViewportResponse
 */
export interface ViewportResponse {
    /**
     *
     * @type {LocationResponse}
     * @memberof ViewportResponse
     */
    northeast: LocationResponse
    /**
     *
     * @type {LocationResponse}
     * @memberof ViewportResponse
     */
    southwest: LocationResponse
}

/**
 * AddressResourceApi - axios parameter creator
 * @export
 */
export const AddressResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * See /address-predictions to get some predictions
         * @summary Return the address details for a given prediction
         * @param {string} sessionToken The token use with le previous /address-predictions call
         * @param {string} predictionId The prediction-id obtained with le previous /address-predictions call
         * @param {string} [language] The language we want to have the results translated to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressDetails: async (
            sessionToken: string,
            predictionId: string,
            language?: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'sessionToken' is not null or undefined
            assertParamExists('getAddressDetails', 'sessionToken', sessionToken)
            // verify required parameter 'predictionId' is not null or undefined
            assertParamExists('getAddressDetails', 'predictionId', predictionId)
            const localVarPath = `/address-details`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (sessionToken !== undefined) {
                localVarQueryParameter['session-token'] = sessionToken
            }

            if (predictionId !== undefined) {
                localVarQueryParameter['prediction-id'] = predictionId
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions
            }
        },
        /**
         * See /address-details to get the details of a prediction
         * @summary Return a list of address predictions for a given input
         * @param {string} sessionToken UUIDv4 token, to use with the following call to /address-details
         * @param {string} input The searched address
         * @param {string} country The country from which we want to find the address
         * @param {string} language The language we want to have the results translated to
         * @param {'ALL' | 'ADDRESS'} [predictionType] The type of address prediction result you want
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressPredictions: async (
            sessionToken: string,
            input: string,
            country: string,
            language: string,
            predictionType?: 'ALL' | 'ADDRESS',
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'sessionToken' is not null or undefined
            assertParamExists('getAddressPredictions', 'sessionToken', sessionToken)
            // verify required parameter 'input' is not null or undefined
            assertParamExists('getAddressPredictions', 'input', input)
            // verify required parameter 'country' is not null or undefined
            assertParamExists('getAddressPredictions', 'country', country)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('getAddressPredictions', 'language', language)
            const localVarPath = `/address-predictions`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            if (sessionToken !== undefined) {
                localVarQueryParameter['session-token'] = sessionToken
            }

            if (input !== undefined) {
                localVarQueryParameter['input'] = input
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language
            }

            if (predictionType !== undefined) {
                localVarQueryParameter['predictionType'] = predictionType
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions
            }
        }
    }
}

/**
 * AddressResourceApi - functional programming interface
 * @export
 */
export const AddressResourceApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = AddressResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * See /address-predictions to get some predictions
         * @summary Return the address details for a given prediction
         * @param {string} sessionToken The token use with le previous /address-predictions call
         * @param {string} predictionId The prediction-id obtained with le previous /address-predictions call
         * @param {string} [language] The language we want to have the results translated to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressDetails(
            sessionToken: string,
            predictionId: string,
            language?: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressPredictionDetailsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddressDetails(
                sessionToken,
                predictionId,
                language,
                options
            )
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
        },
        /**
         * See /address-details to get the details of a prediction
         * @summary Return a list of address predictions for a given input
         * @param {string} sessionToken UUIDv4 token, to use with the following call to /address-details
         * @param {string} input The searched address
         * @param {string} country The country from which we want to find the address
         * @param {string} language The language we want to have the results translated to
         * @param {'ALL' | 'ADDRESS'} [predictionType] The type of address prediction result you want
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressPredictions(
            sessionToken: string,
            input: string,
            country: string,
            language: string,
            predictionType?: 'ALL' | 'ADDRESS',
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressPredictionListView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddressPredictions(
                sessionToken,
                input,
                country,
                language,
                predictionType,
                options
            )
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
        }
    }
}

/**
 * AddressResourceApi - factory interface
 * @export
 */
export const AddressResourceApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = AddressResourceApiFp(configuration)
    return {
        /**
         * See /address-predictions to get some predictions
         * @summary Return the address details for a given prediction
         * @param {string} sessionToken The token use with le previous /address-predictions call
         * @param {string} predictionId The prediction-id obtained with le previous /address-predictions call
         * @param {string} [language] The language we want to have the results translated to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressDetails(
            sessionToken: string,
            predictionId: string,
            language?: string,
            options?: any
        ): AxiosPromise<AddressPredictionDetailsView> {
            return localVarFp
                .getAddressDetails(sessionToken, predictionId, language, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * See /address-details to get the details of a prediction
         * @summary Return a list of address predictions for a given input
         * @param {string} sessionToken UUIDv4 token, to use with the following call to /address-details
         * @param {string} input The searched address
         * @param {string} country The country from which we want to find the address
         * @param {string} language The language we want to have the results translated to
         * @param {'ALL' | 'ADDRESS'} [predictionType] The type of address prediction result you want
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressPredictions(
            sessionToken: string,
            input: string,
            country: string,
            language: string,
            predictionType?: 'ALL' | 'ADDRESS',
            options?: any
        ): AxiosPromise<AddressPredictionListView> {
            return localVarFp
                .getAddressPredictions(sessionToken, input, country, language, predictionType, options)
                .then((request) => request(axios, basePath))
        }
    }
}

/**
 * AddressResourceApi - object-oriented interface
 * @export
 * @class AddressResourceApi
 * @extends {BaseAPI}
 */
export class AddressResourceApi extends BaseAPI {
    /**
     * See /address-predictions to get some predictions
     * @summary Return the address details for a given prediction
     * @param {string} sessionToken The token use with le previous /address-predictions call
     * @param {string} predictionId The prediction-id obtained with le previous /address-predictions call
     * @param {string} [language] The language we want to have the results translated to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressResourceApi
     */
    public getAddressDetails(sessionToken: string, predictionId: string, language?: string, options?: any) {
        return AddressResourceApiFp(this.configuration)
            .getAddressDetails(sessionToken, predictionId, language, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * See /address-details to get the details of a prediction
     * @summary Return a list of address predictions for a given input
     * @param {string} sessionToken UUIDv4 token, to use with the following call to /address-details
     * @param {string} input The searched address
     * @param {string} country The country from which we want to find the address
     * @param {string} language The language we want to have the results translated to
     * @param {'ALL' | 'ADDRESS'} [predictionType] The type of address prediction result you want
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressResourceApi
     */
    public getAddressPredictions(
        sessionToken: string,
        input: string,
        country: string,
        language: string,
        predictionType?: 'ALL' | 'ADDRESS',
        options?: any
    ) {
        return AddressResourceApiFp(this.configuration)
            .getAddressPredictions(sessionToken, input, country, language, predictionType, options)
            .then((request) => request(this.axios, this.basePath))
    }
}
