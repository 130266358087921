import { FiltersType, Localisation } from '../features/MapWrapper/types'

export enum AppActionsType {
    SET_ENABLE_OVERLAY = 'SET_ENABLE_OVERLAY',
    SET_DISABLE_OVERLAY = 'SET_DISABLE_OVERLAY',
    SET_SEARCH_RESULT = 'SET_SEARCH_RESULT',
    UPDATE_FILTERS = 'UPDATE_FILTER',
    SET_PAGE_NUMBER = 'SET_PAGE_NUMBER',
    SET_STORE_PINNED_ID = 'SET_STORE_PINNED_ID',
    SET_SEARCH_POSITION_MAP = 'SET_SEARCH_POSITION_MAP',
    SET_MAP_POSITION = 'SET_MAP_POSITION',
    RESET = 'RESET'
}

export interface AllContext {
    filters?: FiltersType
    mapPosition?: PositionContext
    overlay: OverlayContext
    pageNumber: number
    searchPosition?: PositionContext
    searchResult: SearchResultContext
    storePinnedId?: string
}

export interface PositionContext {
    point?: Localisation
    viewport?: google.maps.LatLngBounds
    withGeolocation?: boolean
}

export interface OverlayContext {
    hasOverlay: boolean
}

export interface SearchResultContext {
    pageSize?: number
    url?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: M[Key] extends undefined
        ? {
              type: Key
          }
        : {
              type: Key
              payload?: M[Key]
          }
}
