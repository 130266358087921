import React from 'react'

import { useTranslation } from 'react-i18next'

import { FiltersWrapper } from '../styles'

import { OnlyOpened } from './OnlyOpened'

export const Filters = () => {
    const { t } = useTranslation('home')

    return (
        <FiltersWrapper data-testid="filtersWrapper">
            {t('storeStatus')}
            <OnlyOpened />
        </FiltersWrapper>
    )
}
