import styled from 'styled-components'

import { Spacing } from '@nickel/foundations/tokens/layout'
import { FontSize, FontWeight } from '@nickel/foundations/tokens/typography'

export const Wrapper = styled.div`
    background: linear-gradient(180deg, #cce4ff 0%, #e5f2ff 100%);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: ${Spacing.MEDIUM};
    padding: ${Spacing.LARGE};
`

export const Title = styled.div`
    font-size: ${FontSize.LARGE};
    font-weight: ${FontWeight.BOLD};
`
