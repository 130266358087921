import styled, { css } from 'styled-components'

import Button from '@nickel/core-ui/components/Button'
import { References } from '@nickel/foundations/tokens/color'
import { Breakpoint, Spacing } from '@nickel/foundations/tokens/layout'
import { FontSize } from '@nickel/foundations/tokens/typography'

export const LocalisationButton = styled(Button)<{ isMobileFullScreen: boolean; isResultSearch: boolean }>`
    align-items: center;
    background-color: ${References.REF_NEUTRAL_N0};
    justify-content: center;
    text-align: center;
    width: 100%;

    ${({ isMobileFullScreen }) => {
        if (isMobileFullScreen)
            return css`
                background-color: ${References.REF_NEUTRAL_N0};
                border: none;
                // Margin top + button's font + button's padding
                bottom: calc(-1 * (${Spacing.LARGE} + ${FontSize.LARGE} + ${Spacing.XLARGE}));
                display: flex;
                left: ${Spacing.LARGE};
                padding: 0 ${Spacing.MEDIUM};
                position: absolute;
                width: fit-content;
            `
    }}
    ${({ isResultSearch }) => {
        if (isResultSearch)
            return css`
                background-color: transparent;
                border: none;
                bottom: auto;
                display: flex;
                justify-content: left;
                left: auto;
                margin: ${Spacing.XXLARGE} 0 0 0;
                padding: 0;
                place-self: initial;
                position: relative;
                width: 100%;
                z-index: 10;

                :hover {
                    background-color: transparent;
                    color: ${References.REF_NEUTRAL_N900};
                }

                svg {
                    background-color: ${References.REF_NEUTRAL_N10};
                    border-radius: ${Spacing.XXLARGE};
                    height: ${Spacing.XXLARGE};
                    padding: ${Spacing.MEDIUM};
                    width: ${Spacing.XXLARGE};
                }
            `
    }}
        // Fix spinner grow flex from core ui
    > div {
        flex-grow: unset !important;
    }

    @media (min-width: ${Breakpoint.MEDIUM}) {
        background-color: unset;
        width: fit-content;
    }

    // Fix weird core-ui behaviour
    @media (hover: none) {
        &:hover:not(:active):not(:disabled) {
            background-color: ${References.REF_NEUTRAL_N0};
            color: ${References.REF_NEUTRAL_N900};

            svg {
                fill: ${References.REF_NEUTRAL_N900};
            }
        }
    }
`
