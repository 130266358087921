import React from 'react'

import { Details } from 'src/features/Details/Details'
import { useTrackPageView } from 'src/utils/Tracking/useTrackPageView'

export const PosDetails = () => {
    useTrackPageView()
    return (
        <div data-testid="posDetails">
            <Details />
        </div>
    )
}
